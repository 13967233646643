export var PlayerControlsStates;
(function (PlayerControlsStates) {
    PlayerControlsStates["PlayOrPause"] = "PlayOrPause";
    PlayerControlsStates["AdControls"] = "AdControls";
    PlayerControlsStates["PauseAdControls"] = "PauseAdControls";
    PlayerControlsStates["Seeking"] = "Seeking";
    PlayerControlsStates["MoreInfo"] = "MoreInfo";
    PlayerControlsStates["SkipButton"] = "SkipButton";
    PlayerControlsStates["LiveToVod"] = "LiveToVod";
    PlayerControlsStates["TempPass"] = "TempPass";
    PlayerControlsStates["MediaOptions"] = "MediaOptions";
    PlayerControlsStates["QOSPanel"] = "QOSPanel";
    PlayerControlsStates["LiveControls"] = "LiveControls";
})(PlayerControlsStates || (PlayerControlsStates = {}));
export var LiveGuideV2States;
(function (LiveGuideV2States) {
    LiveGuideV2States["Tabs"] = "Tabs";
    LiveGuideV2States["Channels"] = "Channels";
    LiveGuideV2States["Filters"] = "Filters";
    LiveGuideV2States["Sports"] = "Sports";
    LiveGuideV2States["AssetInfo"] = "AssetInfo";
})(LiveGuideV2States || (LiveGuideV2States = {}));
export var PlayerStates;
(function (PlayerStates) {
    PlayerStates["Ready"] = "Ready";
    PlayerStates["LPPlayer"] = "LPPlayer";
    PlayerStates["Epg"] = "Epg";
    PlayerStates["PlayerInActive_Yes"] = "PlayerInActive.Yes";
    PlayerStates["PlayerInActive_No"] = "PlayerInActive.No";
    PlayerStates["LPPlayer_Controls"] = "LPPlayer.Controls";
    PlayerStates["LPPlayer_Controls_Seeking"] = "LPPlayer.Controls.Seeking";
    PlayerStates["LPPlayer_LiveToVod"] = "LPPlayer.LiveToVod";
    PlayerStates["LPPlayer_MediaOptions"] = "LPPlayer.MediaOptions";
    PlayerStates["LPPlayer_TempPass"] = "LPPlayer.TempPass";
    PlayerStates["LPPlayer_SkipButton"] = "LPPlayer.SkipButton";
    PlayerStates["PlayerEndCard"] = "PlayerEndCard";
    PlayerStates["PlayerEndCard_MiniPlayer"] = "PlayerEndCard.MiniPlayer";
    PlayerStates["SFVODEndCard"] = "SFVODEndCard";
    PlayerStates["PlayerEndcardFER"] = "PlayerEndCardFER";
})(PlayerStates || (PlayerStates = {}));
export var FiltersStates;
(function (FiltersStates) {
    FiltersStates["FiltersList"] = "FiltersList";
    FiltersStates["Content"] = "Content";
})(FiltersStates || (FiltersStates = {}));
export var FiltersContentStates;
(function (FiltersContentStates) {
    FiltersContentStates["Container"] = "Container";
    FiltersContentStates["Error"] = "Error";
})(FiltersContentStates || (FiltersContentStates = {}));
export var EventsWithFiltersStates;
(function (EventsWithFiltersStates) {
    EventsWithFiltersStates["Filters"] = "Filters";
    EventsWithFiltersStates["Results"] = "Results";
    EventsWithFiltersStates["Error"] = "Error";
})(EventsWithFiltersStates || (EventsWithFiltersStates = {}));
export var ShowsStates;
(function (ShowsStates) {
    ShowsStates["BrandLabelsList"] = "BrandLabelsList";
    ShowsStates["Error"] = "Error";
    ShowsStates["LinksSelectableGroup"] = "LinksSelectableGroup";
    ShowsStates["LoadSelectedBrandData"] = "LoadSelectedBrandData";
})(ShowsStates || (ShowsStates = {}));
export var MyProfileStates;
(function (MyProfileStates) {
    MyProfileStates["LogoutConfirmation"] = "LogoutConfirmation";
    MyProfileStates["LogoutConfirmation_Notification"] = "LogoutConfirmation.Notification";
    MyProfileStates["NBCUserProfile"] = "NBCUserProfile";
    MyProfileStates["TvProvider"] = "TvProvider";
})(MyProfileStates || (MyProfileStates = {}));
export var MainStates;
(function (MainStates) {
    MainStates["Featured"] = "Featured";
    MainStates["Scroller"] = "Scroller";
})(MainStates || (MainStates = {}));
