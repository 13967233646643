import { Router, Log } from '@lightningjs/sdk';
import { bootApp } from './api/Api';
import { EVENTS } from './lib/analytics/types';
import { sendMetric } from './lib/analytics/Analytics';
import { isXclass } from './helpers';
import GlobalNavigationSingleton from './helpers/globalNavigation';
import { ROUTE } from './constants';
import routerConfig from './routes';
import { EpgGuideController } from './components/EpgGuideV2/EpgGuideController';
class RouterConfig {
    constructor() {
        this.bootFailed = false;
        this.root = ROUTE.home;
        this.boot = async () => {
            try {
                await bootApp();
                this.bootFailed = false;
            }
            catch (error) {
                this.bootFailed = true;
            }
        };
        this.routes = routerConfig;
        this._events = {
            navigation: null,
        };
        this.beforeEachRoute = (_, request) => {
            var _a;
            const path = (_a = request.route) === null || _a === void 0 ? void 0 : _a.path;
            if (path && !isPlayerPath(path)) {
                EpgGuideController.clear();
            }
            return Promise.resolve(true);
        };
        // Trigger mparticle analytics each time a new route loads.
        // This event seems to only work in the 4.x.x sdk.
        this.afterEachRoute = (request) => {
            var _a, _b, _c, _d;
            if (this.bootFailed) {
                Router.navigate(ROUTE.error, { reBoot: true });
                this.bootFailed = false;
                return;
            }
            if (this._events.navigation) {
                this._events.navigation();
            }
            const path = (_a = request.route) === null || _a === void 0 ? void 0 : _a.path;
            if (!path || isPathSkippable(path))
                return;
            if (!request.register.get('preventClickMetric')) {
                const hash = (request === null || request === void 0 ? void 0 : request.hash) || '';
                const name = (_d = (_c = (_b = GlobalNavigationSingleton.items) === null || _b === void 0 ? void 0 : _b.find) === null || _c === void 0 ? void 0 : _c.call(_b, ({ route }) => route === hash)) === null || _d === void 0 ? void 0 : _d.label;
                // For TESTING PURPOSE ONLY
                if (name === 'Home' && performance.getEntriesByName('startRouting').length > 0) {
                    performance.mark('endRouting');
                    // Measure the duration between marks
                    performance.measure('routingDuration', 'startRouting', 'endRouting');
                    const measures = performance.getEntriesByName('routingDuration');
                    Log.info('Routing Duration:', measures);
                }
            }
            // Additional Paths done async.
            if (isPathSkippable(path, [
                ROUTE.showHome,
                ROUTE.video,
                ROUTE.live,
                ROUTE.watch,
                ROUTE.movieHome,
                ROUTE.olympicsReplays,
                ROUTE.olympicsHighlights,
            ]))
                return;
            sendMetric(EVENTS.PAGE_LOAD, getEventDataFromRequest(request));
            Router.focusPage();
        };
        if (isXclass()) {
            this.updateHash = false;
        }
    }
    set events(cb) {
        if (typeof cb !== 'object') {
            return;
        }
        Object.keys(cb).forEach((key) => {
            if (key in this._events && typeof cb[key] === 'function') {
                this._events[key] = cb[key];
            }
        });
    }
}
export default new RouterConfig();
export const isPathSkippable = (path, additionalPaths = []) => [ROUTE.boot, ROUTE.notFound, ROUTE.error, ...additionalPaths].includes(path);
const isPlayerPath = (path) => [ROUTE.live, ROUTE.watch, ROUTE.video].includes(path);
const getEventDataFromRequest = (request) => {
    return {
        hash: request._hash,
        path: request._route._cfg.path,
        // First load of shows page only use default brand and cat.
        // Subsequent changes will track as separate page loads.
        brand: 'None',
        category: request._hash === ROUTE.shows ? 'Popular' : 'None',
    };
};
