import { Lightning } from '@lightningjs/sdk';
import { formatSecondsAsHhMmSs, setSmooth } from '../../../helpers';
import SkipButton from '../../buttons/SkipButton';
import SeekablePlayerControls from './SeekablePlayerControls';
import VODStrategy from './strategies/VODStrategy';
import { SCREEN_SIZE } from '../../../constants';
import { WithTrickPlay } from './hoc/WithTrickPlay';
import { WithPauseAds } from './hoc/WithPauseAds';
import { WithSkipButton } from './hoc/WithSkipButton';
import { WithAdBreaks } from './hoc/WithAdBreaks';
class VODPlayerControls extends WithAdBreaks(SeekablePlayerControls) {
    constructor() {
        super(...arguments);
        this._strategy = new VODStrategy(this);
    }
    static _template() {
        return super._template({
            PlayerControls: {
                TitleContainer: {
                    HeadingContainer: undefined,
                    Clip: {
                        flex: {},
                        flexItem: { marginTop: 4 },
                        y: 77,
                        Icon: {},
                    },
                },
                TrickPlay: {
                    y: 686,
                    rect: true,
                    w: 416,
                    h: 234,
                    color: 0xffaaaaaa,
                    clipping: true,
                    alpha: 0,
                    Image: {
                        texture: {
                            type: Lightning.textures.ImageTexture,
                            src: '',
                        },
                    },
                },
            },
            SkipButton: {
                type: SkipButton,
            },
        });
    }
    _updateProgress([currentTime, duration]) {
        if (!duration)
            return;
        const progressTime = this._adbreakMarkersDelegate.getBarWidth(currentTime, duration);
        const trickPlayMax = SCREEN_SIZE.width * 0.7;
        const trickPlayMin = SCREEN_SIZE.width * 0.07;
        const trickPlayX = Math.min(Math.max(0, progressTime - trickPlayMin), trickPlayMax);
        setSmooth(this.tag('CurrentBar'), 'w', progressTime, { duration: 0.2 });
        setSmooth(this.tag('SeekBar'), 'x', progressTime + 1, { duration: 0.2 });
        setSmooth(this.tag('TrickPlay'), 'x', trickPlayX, { duration: 0.2 });
        this._aTime.text.text = formatSecondsAsHhMmSs(currentTime);
    }
    _setRating() {
        this._tvRating.setup();
    }
}
// Workaround for TS types not being correctly asserted with HOCs
export default class extends WithSkipButton(WithPauseAds(WithTrickPlay(VODPlayerControls))) {
}
