import { Language, Lightning, Registry, Utils } from '@lightningjs/sdk';
import BaseComponent from '../../base';
import TextButton from '../../buttons/TextButton';
import ImageButton from '../../buttons/ImageButton';
import TVRating from './TVRating';
import { checkSetMvpdLogo, FastImg, setSmooth, templateDeepMerge } from '../../../helpers';
import { PlayerStatusEvent, SubtitleEvent, TimeChangeEvent } from '../../../player/model/event';
import { PlayerStatus } from '../../../player/model/PlayerStatus';
import { DebugControllerSingleton } from '../../../util/debug/DebugController';
import { ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, PlayerControlsStates, SCREEN_SIZE, TRICK_PLAY_ACTIONS, VERTICAL_ALIGN, } from '../../../constants';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder';
import { getControlsAnalytics } from '../helpers/metadata';
import { OLY_IMPOLICY } from '../../../constants';
import { useProgress } from './hooks/useProgress';
import Announcer from '../../../lib/tts/Announcer';
class BasePlayerControls extends BaseComponent {
    constructor() {
        super(...arguments);
        this.tempPassCtaClicked = false;
    }
    static _template(override) {
        return templateDeepMerge({
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            rect: true,
            colorTop: COLORS.black1,
            colorBottom: COLORS.black2,
            PlayerControls: {
                x: 80,
                PauseAdOverlayContainer: {
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    x: -80,
                    y: 0,
                    alpha: 0,
                    zIndex: 0,
                    rect: true,
                    colorTop: COLORS.black1,
                    colorBottom: COLORS.black2,
                },
                SettingsContainer: {
                    y: 57,
                    w: 1840,
                    flex: { direction: FLEX_DIRECTION.row },
                    MediaOptions: {
                        flexItem: { marginLeft: 45 },
                        h: 63,
                        w: 63,
                        mountY: -0.5,
                        type: ImageButton,
                        radius: 31,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.mediumGray3,
                        padding: 0,
                        alpha: 0,
                        announce: Language.translate('closed_captioning'),
                    },
                    QOSPanel: {
                        h: 63,
                        flexItem: { marginLeft: 15 },
                        type: TextButton,
                        radius: 33,
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        label: Language.translate('DEBUG'),
                        focusFontColor: COLORS.black,
                        unfocusFontColor: COLORS.black5,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.mediumGray3,
                        autoWidth: true,
                        visible: false,
                        padding: 22,
                    },
                    MvpdLogoWarper: {
                        flexItem: {
                            alignSelf: ALIGN_SELF.stretch,
                            grow: 1,
                            marginRight: 40,
                        },
                        flex: {
                            direction: FLEX_DIRECTION.row,
                            justifyContent: JUSTIFY_CONTENT.flexEnd,
                        },
                        MvpdLogo: {
                            flexItem: {},
                            mountY: -0.5,
                            src: null,
                            alpha: 0,
                        },
                    },
                },
                TVRating: {
                    y: 150,
                    type: TVRating,
                },
                TitleContainer: {
                    y: this.TITLE_CONTAINER_Y,
                    HeadingContainer: {
                        flex: { direction: FLEX_DIRECTION.row },
                        PlayerControlsBrandLogoWrapper: {
                            y: 30,
                            x: 13,
                            w: 60,
                            h: 60,
                            mount: 0,
                            PlayerControlsBrandLogo: {
                                src: '',
                                mountY: 0,
                                mountX: 0.5,
                            },
                        },
                        Title: {
                            rtt: true,
                            text: {
                                textColor: COLORS.white,
                                verticalAlign: VERTICAL_ALIGN.middle,
                                fontSize: 58,
                                fontFace: FONT_FACE.light,
                                maxLines: 1,
                                wordWrapWidth: BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH,
                            },
                        },
                    },
                    SecondaryTitle: {
                        rtt: true,
                        y: 76,
                        x: 82,
                        text: {
                            textColor: COLORS.white,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 26,
                            fontFace: FONT_FACE.regular,
                            maxLines: 1,
                            wordWrapWidth: BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH,
                        },
                    },
                    Description: {
                        y: 150,
                        x: 82,
                        alpha: 0.01,
                        text: {
                            textColor: COLORS.white,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 26,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            wordWrapWidth: 1534,
                        },
                    },
                },
                PauseAdTitleContainer: {
                    y: BasePlayerControls.TITLE_CONTAINER_Y,
                    alpha: 0,
                    zIndex: 1,
                    Description: {
                        y: 76,
                        x: 82,
                        alpha: 1,
                        text: {
                            text: Language.translate('pause_ad_info'),
                            textColor: COLORS.white,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 26,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            wordWrapWidth: 1534,
                        },
                    },
                },
                ControlsWrapper: {
                    y: 920,
                    PlayOrPause: {
                        w: 25,
                        h: 25,
                        src: Utils.asset('images/player/pause.png'),
                    },
                    Progress: {
                        x: 86,
                        AdBreakPoints: {
                            y: 7,
                        },
                        TotalBar: {
                            w: BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH,
                            h: 8,
                            y: 7,
                            rect: true,
                            color: COLORS.charcoalGrey,
                        },
                        CurrentBar: {
                            w: 0,
                            h: 8,
                            y: 7,
                            rect: true,
                            color: COLORS.white,
                        },
                        SeekBar: {
                            BigCircle: {
                                x: 5,
                                y: 11,
                                mountX: 1,
                                mountY: 0.5,
                                alpha: 0,
                                color: COLORS.warmGray,
                                texture: Lightning.Tools.getRoundRect(30, 30, 15),
                            },
                            SmallCircle: {
                                x: 0,
                                y: 11,
                                mountX: 1,
                                mountY: 0.5,
                                color: COLORS.white,
                                texture: Lightning.Tools.getRoundRect(20, 20, 10),
                            },
                        },
                    },
                    Timer: {
                        x: 86,
                        y: 35,
                        ATime: {
                            text: {
                                textColor: COLORS.white,
                                verticalAlign: VERTICAL_ALIGN.middle,
                                fontSize: 28,
                                fontFace: FONT_FACE.regular,
                                maxLines: 1,
                                text: '00:00',
                            },
                        },
                        BTime: {
                            x: BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH,
                            mountX: 1,
                            text: {
                                textColor: COLORS.white,
                                verticalAlign: VERTICAL_ALIGN.middle,
                                fontSize: 28,
                                fontFace: FONT_FACE.regular,
                                maxLines: 1,
                                text: '00:00',
                            },
                        },
                    },
                },
            },
            AdControls: {
                alpha: 0,
                AdControlIcon: {
                    x: 960,
                    y: 920,
                    mountX: 0.5,
                    w: 40,
                    h: 40,
                    src: Utils.asset('images/player/pause.png'),
                },
            },
        }, override);
    }
    static _states() {
        return [];
    }
    getTitleContainerY() {
        return BasePlayerControls.TITLE_CONTAINER_Y;
    }
    _init() {
        this.setPlayPauseState();
        this.tag('MediaOptions').icon = 'images/player/cc.png';
        this._aTime = this.tag('ATime');
        this._bTime = this.tag('BTime');
        this._title = this.tag('Title');
        this._titleContainer = this.tag('TitleContainer');
        this._secondaryTitle = this.tag('SecondaryTitle');
        this._tertiaryTitle = this.tag('TertiaryTitle');
        this._description = this.tag('Description');
        this._tvRating = this.tag('TVRating');
        this._progress = useProgress(this._updateProgress.bind(this));
    }
    _handleLeft() {
        this._keyMap.left();
    }
    _handleRight() {
        this._keyMap.right();
    }
    _handleUp() {
        this._keyMap.up();
    }
    _handleDown() {
        this._keyMap.down();
    }
    _firstActive() {
        checkSetMvpdLogo(this);
    }
    _active() {
        // @ts-expect-error TS(2551): Property 'enable' does not exist on type 'DebugCon... Remove this comment to see the full error message
        this.tag('QOSPanel').visible = DebugControllerSingleton.enable;
    }
    _disable() {
        this._keyMap.reset();
    }
    setup() {
        var _a;
        const { program, stream } = PlayerStoreSingleton;
        if (!program || !stream)
            return;
        this.stage.gc(); // Workaround for memory exhaustion issue
        this._strategy.init(stream, program);
        (_a = this.tag('PlayerControlsBrandLogo')) === null || _a === void 0 ? void 0 : _a.patch({
            texture: FastImg(stream.whiteBrandLogo).contain(60, 60, OLY_IMPOLICY.SQUARE),
        });
        this._setRating(program);
    }
    _playerSubscriptionHandler(event) {
        if (event instanceof PlayerStatusEvent) {
            switch (event.status) {
                case PlayerStatus.PLAYING:
                    this.playOrPause = true;
                    break;
                case PlayerStatus.PAUSED:
                    if (this._getState() !== PlayerControlsStates.Seeking)
                        this.playOrPause = false;
                    break;
                default:
                    break;
            }
        }
        else if (event instanceof TimeChangeEvent) {
            this._strategy.update(event);
        }
        else if (event instanceof SubtitleEvent) {
            const { tracks } = event.subtitleData;
            if (tracks && (tracks === null || tracks === void 0 ? void 0 : tracks.length) > 0) {
                this._showCCOption();
            }
        }
    }
    _attach() {
        this._subscription = new SubscriptionBuilder()
            .with(SubscriptionSources.PLAYER)
            // We have to use bind(this), because when defining
            // the callback as a class property, we lose access to super
            .subscribe(this._playerSubscriptionHandler.bind(this));
    }
    _detach() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
        this._progress.unsubscribe();
        this._player = null;
    }
    _updateProgress([currentTime, duration]) {
        if (!duration)
            return;
        const position = Math.min(BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH, (currentTime * BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH) / duration);
        const correctedPosition = duration - currentTime <= 60 ? BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH : position;
        this.tag('CurrentBar').w = correctedPosition;
        setSmooth(this.tag('SeekBar'), 'x', correctedPosition + 1, {
            duration: 0.2,
        });
    }
    _showCCOption() {
        this.tag('MediaOptions').alpha = 1;
    }
    _reset() {
        this.tag('MediaOptions').alpha = 0;
    }
    _setFeature(feature, value) {
        if (this._features[feature] !== undefined) {
            this._features[feature] = value;
        }
    }
    set playOrPause(v) {
        this._setControlIcon(v ? 'images/player/pause.png' : 'images/player/play.png');
    }
    _setControlIcon(icon) {
        this.tag('PlayOrPause').src = Utils.asset(icon);
    }
    setPlayPauseState() {
        this._setState(PlayerControlsStates.PlayOrPause, [{ _analytics: getControlsAnalytics() }]);
    }
    showAdControls() {
        this.tag('AdControls').patch({ alpha: 1 });
        this.tag('PlayerControls').patch({ alpha: 0 });
        this._setState(PlayerControlsStates.AdControls);
    }
    hideAdControls() {
        this.tag('PlayerControls').patch({ alpha: 1 });
        this.tag('AdControls').patch({ alpha: 0 });
    }
    fireHandleDownEvent() { }
    _toggleQOSPanel() {
        DebugControllerSingleton.qosEnabled = !DebugControllerSingleton.qosEnabled;
        this.fireAncestors('$toggleQOSPanel');
    }
    _setTranslation() {
        this.tag('PlayerControls').patch({
            SettingsContainer: {
                MediaOptions: {
                    announce: Language.translate('closed_captioning'),
                },
                QOSPanel: {
                    label: Language.translate('DEBUG'),
                },
            },
        });
    }
    _setRating(program) {
        this._tvRating.setup(program);
    }
    _announce(message, options = { append: false, notification: false }, timeout = 0) {
        Registry.setTimeout(() => {
            Announcer.announce(message, options);
        }, timeout);
    }
    onPlayAnnounce() {
        this._announce([TRICK_PLAY_ACTIONS.play]);
    }
    onPauseAnnounce() {
        this._announce([TRICK_PLAY_ACTIONS.pause]);
    }
    _showDescription() { }
}
BasePlayerControls.PROGRESSBAR_TOTAL_WIDTH = 1674;
BasePlayerControls.TITLE_CONTAINER_Y = 750;
export default BasePlayerControls;
