import { Storage } from '@lightningjs/sdk';
import { formatAppVersion, getConfigName, getIsDayZero, getMpid, getSportsPageName, getTimezone, } from '../helpers';
import { LANGUAGES, STORAGE_KEYS } from '../constants';
import version from '../version';
import TvPlatform from '../lib/tv-platform';
import AuthenticationSingleton from '../authentication/Authentication';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { EntityPageType, QueryNames, } from './generated/types';
import { nationalBroadcastType, nbcAffiliateName, telemundoAffiliateName } from '../api/Live';
export const Variables = {
    homePageVariables() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, 'homepage')), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    sportsPageVariables() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.SportLanding, getSportsPageName())), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    olympicsPageVariables() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.OlympicsPage, 'homepage')), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    olympicsContentHubPageVariables(name) {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.OlympicsContentHub, name)), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    getPlaylistMachineName() {
        const playlistMachineName = Storage.get(STORAGE_KEYS.PLAYLIST_MACHINE_NAME);
        return playlistMachineName ? { playlistMachineName } : {};
    },
    showsPageVariables() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, 'paginatedAllShows')), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    liveSchedulePageVariables() {
        const nbcName = nbcAffiliateName();
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.LiveSchedule, nbcName || 'wnbc')), this.getCoastValues(nationalBroadcastType(), nbcName)), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    streamPageVariables(name, callSign) {
        const nbcName = nbcAffiliateName();
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Stream, name || nbcName || 'wnbc')), this.getCoastValues(nationalBroadcastType(), nbcName)), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage, callSign });
    },
    slePageVariables(pid) {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Stream, `${pid}`)), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    showPageVariables(type, urlalias) {
        const variables = Object.assign(Object.assign({}, this.getCommonVariables(type, urlalias)), { userId: !this.getPersonalizationFlag() ? getMpid() : '0' });
        return Object.assign(Object.assign({}, variables), { queryName: QueryNames.BonanzaPage });
    },
    networksPageVariables() {
        return Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, 'allBrands')), { queryName: QueryNames.BonanzaPage });
    },
    networkLandingPageVariables(machineName) {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.BrandLanding, machineName)), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { queryName: QueryNames.BonanzaPage });
    },
    mixedEditorialShelfVariables(queryVariables) {
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables()), queryVariables), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), { seoFriendlyName: '', queryName: QueryNames.MixedEditorialShelf });
    },
    searchPageVariables() {
        return Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, '')), { queryName: QueryNames.BonanzaPage });
    },
    videoPageVariables(videoId) {
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Video, videoId)), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), this.getPlaylistMachineName()), { queryName: QueryNames.FeaturedShowsSection });
    },
    endCardVariables(type, videoId) {
        return Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, '')), this.getPlaylistMachineName()), { type, mpxGuid: `${videoId}`, queryName: QueryNames.EndCard });
    },
    endCardSLEVariables(type, videoId) {
        return Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.Page, '')), this.getPlaylistMachineName()), { type, pid: videoId, queryName: QueryNames.EndCard });
    },
    userInteractionsVariables() {
        return Object.assign(Object.assign({}, this.getCommonVariables('', '')), { queryName: QueryNames.UserInteractions });
    },
    componentsForPlaceholdersVariables(variables) {
        return Object.assign(Object.assign({ queryName: QueryNames.ComponentsForPlaceholders }, variables), { userId: !this.getPersonalizationFlag() ? getMpid() : '0' });
    },
    getMergedVariables(variables) {
        return Object.assign(Object.assign({}, this.getCommonVariables()), variables);
    },
    getPersonalizationFlag() {
        console.log('LaunchDarkly getPersonalizationFlag', LaunchDarklySingleton.getAllFlag());
        return LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.removePersonalization);
    },
    getCommonVariables(type, name) {
        const userId = !this.getPersonalizationFlag() ? getMpid() : '0';
        // Todo: make this dynamic, hardcoded for now
        return {
            app: getConfigName(),
            name,
            type,
            userId,
            platform: TvPlatform.bffPlatform,
            ld: true,
            timeZone: getTimezone(),
            oneApp: true,
            language: Storage.get(STORAGE_KEYS.APP_LANGUAGE) || LANGUAGES.DEFAULT,
            authorized: AuthenticationSingleton.isAuthenticated(),
            isDayZero: getIsDayZero(),
            appVersion: formatAppVersion(version),
        };
    },
    getGlobalNavigationVariables() {
        return Object.assign(Object.assign({}, this.getCommonVariables()), { queryName: QueryNames.GlobalNavigation });
    },
    getCoastValues(nationalBroadcastType, nbcAffiliateName) {
        return nationalBroadcastType && nbcAffiliateName
            ? { nationalBroadcastType, nbcAffiliateName }
            : {};
    },
    getTelemundoAffiliateName(telemundoAffiliateName) {
        return telemundoAffiliateName ? { telemundoAffiliateName } : {};
    },
    getScrollFromSeasonVariables(season, urlAlias) {
        return Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.TitleV2, urlAlias)), { seasonNumber: parseInt(season), urlAlias });
    },
    olympicsVideoPageVariables(videoId) {
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.getCommonVariables(EntityPageType.OlympicsVideo, videoId)), this.getCoastValues(nationalBroadcastType(), nbcAffiliateName())), this.getTelemundoAffiliateName(telemundoAffiliateName())), this.getPlaylistMachineName()), { queryName: QueryNames.FeaturedShowsSection });
    },
};
export default Variables;
