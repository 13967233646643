import { Lightning } from '@lightningjs/sdk';
import { createXumoHoleShader, SecondaryPlayerSingleton } from '../lib/SecondaryPlayer';
import { fadeComponent, isXclass } from '../helpers';
import { COLORS, SCREEN_SIZE } from '../constants';
import { PLAYER_TYPE } from '../player/core/PlayerFactory';
import isNumber from 'lodash/isNumber';
export default class LongScrollPage extends Lightning.Component {
    static _template() {
        return {
            BG: {
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                rect: true,
                color: COLORS.black,
                shader: null,
            },
        };
    }
    _init() {
        this._bgPlayerSubscription = SecondaryPlayerSingleton.subscribe(({ activated, type }) => {
            const element = this.tag('BG');
            const fadeEndValue = Number(!activated);
            if (type === PLAYER_TYPE.BACKGROUND && element.alpha !== fadeEndValue) {
                fadeComponent(element, fadeEndValue);
            }
            if (isXclass() && type === PLAYER_TYPE.PREVIEW) {
                element.shader = createXumoHoleShader(activated);
                this._defaultClearColor = this.stage.getClearColor();
                this.stage.setClearColor(COLORS.transparent);
            }
        });
    }
    _detach() {
        var _a;
        if (this._defaultClearColor) {
            this.stage.setClearColor(this._defaultClearColor);
        }
        (_a = this._bgPlayerSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._bgPlayerSubscription = undefined;
    }
    _setBackgroundColor(color) {
        this.tag('BG').color = color;
    }
    $scrollRequested({ position, immediate = false }) {
        var _a, _b, _c, _d;
        const scrollElement = this.tag('Scroller');
        const resultEle = this.tag('Results');
        if (scrollElement && isNumber(position.row)) {
            this._setState('Scroller', [{ immediate }]);
            const instanceIndex = scrollElement.getIndexByInstanceID(position.instanceID);
            const index = instanceIndex && instanceIndex !== position.row ? instanceIndex : position.row;
            // if index is out of bounds pick last index in row
            // otherwise fallback to first element in row
            const positionIndex = !(position === null || position === void 0 ? void 0 : position.content) || !((_a = scrollElement.items[index]) === null || _a === void 0 ? void 0 : _a.items)
                ? 0
                : position.content <= scrollElement.items[index].items.length - 1
                    ? position.content
                    : ((_b = scrollElement.items[index].items) === null || _b === void 0 ? void 0 : _b.length) - 1;
            scrollElement.setIndex(index, immediate);
            (_d = (_c = scrollElement.items[index]) === null || _c === void 0 ? void 0 : _c.setIndex) === null || _d === void 0 ? void 0 : _d.call(_c, positionIndex);
        }
        else if (resultEle && isNumber(position.row)) {
            this._setState('Results');
        }
    }
}
