import BasePlatform, { PlatformSubscriptionType, SubscriptionWrapper, TV_PLATFORM_TAG, } from '../base';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from '../types';
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import { SupportedPlatforms } from '../../../graphql/generated/types';
import { KeyCodes, Keys, STORAGE_KEYS, USER_OPT_OUT_PREFERENCE } from '../../../constants';
import KeplerAnnounce from '../../tts/Announces/KeplerAnnounce';
import BaseAnnounce from '../../tts/Announces/BaseAnnounce';
import { Registry, Storage } from '@lightningjs/sdk';
import { INCOMING_KEPLER_MESSAGE_TYPE, OUTGOING_KEPLER_MESSAGE_TYPE, } from './types';
import { DebugControllerSingleton } from '../../../util/debug/DebugController';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
class KeplerSubscriptionWrapper extends SubscriptionWrapper {
    constructor(unsubscribeCb) {
        super();
        this.stale = false;
        this._unsubscribeCb = unsubscribeCb;
    }
    unsubscribe() {
        var _a;
        this.stale = true;
        (_a = this._unsubscribeCb) === null || _a === void 0 ? void 0 : _a.call(this);
    }
}
export default class KeplerPlatform extends BasePlatform {
    constructor() {
        super(...arguments);
        this._platformName = 'Kepler';
        this._lemonadePlatform = LEMONADE_PLATFORM.KEPLER;
        this._bffPlatform = SupportedPlatforms.FireTvLightning;
        this._streamingProtocol = StreamingProtocol.DASH;
        this._appIdentifier = APP_IDENTIFIER.KEPLER;
        this.subscribe = (evt, callback) => {
            // Filter stale events
            this._subscriptions = this._subscriptions.filter(({ stale }) => !stale);
            if (evt === PlatformSubscriptionType.VOICE) {
                const handleMessage = (data) => callback(true);
                Registry.addEventListener(window, 'message', handleMessage);
            }
            return undefined;
        };
        this._sendMessageToShell = (messageType) => {
            if (window === null || window === void 0 ? void 0 : window.ReactNativeWebView) {
                const message = { type: messageType };
                window.ReactNativeWebView.postMessage(JSON.stringify(message));
            }
        };
    }
    get devicePartnerId() {
        var _a, _b;
        return ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.identity) === null || _b === void 0 ? void 0 : _b.device_type) || 'FireTv';
    }
    async init() {
        this._deviceType = 'Fire TV';
        await this.generateDeviceId();
        //listen for the initial config from kepler
        Registry.addEventListener(window, 'message', (msg) => {
            const data = msg.data;
            if (data.type === INCOMING_KEPLER_MESSAGE_TYPE.SET_CONFIG)
                this._keplerConfig = data.payload;
        });
        this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.LOADED);
    }
    /**
     * exit the application
     * @returns {void}
     */
    exit() {
        try {
            this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.EXIT);
            super.exit();
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error on Kepler exit',
                payload: e,
            });
        }
    }
    /**
     * firetv specific key bindings
     * @returns {boolean}
     */
    getPlatformKeyMapping() {
        return Object.assign(Object.assign(Object.assign({}, this.getAtoZAndNumberKeyMapping(true)), super.getPlatformKeyMapping()), { [KeyCodes.kepler.Back]: Keys.BACKSPACE });
    }
    tts() {
        return {
            speak(toSpeak) {
                return window.ReactNativeWebView ? new KeplerAnnounce(toSpeak) : new BaseAnnounce(toSpeak);
            },
            cancel() {
                // todo: cancel method for kepler
            },
        };
    }
    /**
     * Advertiser Id is set on the initial config passed from device
     * @returns {string}
     * @private
     */
    getAdvertiserId() {
        var _a;
        return ((_a = this._keplerConfig) === null || _a === void 0 ? void 0 : _a.adTrackingEnabled)
            ? this._keplerConfig.advertiserId
            : super.getAdvertiserId();
    }
    /**
     * User opt out value for ads  1 = false (disallow), 0 = true (allow)
     * Evaluate the storage (set through privacy settings)
     * The debug override (set in debug menu)
     * Additionally, if user does not have an advertiser id, we should disallow
     * advertiserId will come back as a blank string if not enabled from XBox
     *
     * @returns {boolean}
     */
    getUserOptOut() {
        var _a;
        try {
            const storageOptOut = Number(Storage.get(STORAGE_KEYS.USER_OPT_OUT));
            return storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE ||
                DebugControllerSingleton.userOptOut ||
                !((_a = this._keplerConfig) === null || _a === void 0 ? void 0 : _a.adTrackingEnabled)
                ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        }
        catch (e) {
            return super.getUserOptOut();
        }
    }
}
