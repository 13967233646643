import { Language } from '@lightningjs/sdk';
import { formatSecondsAsHhMmSs } from '../../../../helpers';
import { createProgressHookEvent } from '../hooks/useProgress';
import VODStrategy from './VODStrategy';
export default class FERStrategy extends VODStrategy {
    init(stream) {
        var _a;
        this._totalTime = stream.duration;
        (_a = this.parent.tag('PlayerControlsBrandLogoWrapper')) === null || _a === void 0 ? void 0 : _a.patch({
            y: 90,
        });
        this.parent._titleContainer.patch({
            y: this.parent.constructor.TITLE_CONTAINER_Y,
        });
        this.parent._title.patch({
            flexItem: {
                marginLeft: 8,
                marginTop: 38,
            },
            text: {
                text: stream.league || stream.sports || Language.translate('program_unavailable'),
                fontSize: 28,
            },
        });
        this.parent._secondaryTitle.patch({
            text: { text: stream.title, fontSize: 58 },
        });
        this.parent._tertiaryTitle.patch({ text: { text: stream.tertiaryTitle } });
        this.parent._description.patch({
            y: 185,
            text: { text: stream.secondaryTitle },
        });
        this.parent._aTime.text.text = '00:00';
        this.parent._bTime.text.text = formatSecondsAsHhMmSs(stream.duration);
        this._progress.set(createProgressHookEvent({
            currentTime: 0,
            duration: this._totalTime,
        }));
    }
}
