import { Subject, filter } from 'rxjs';
export var ModalTypes;
(function (ModalTypes) {
    ModalTypes["UPCOMING"] = "upcoming";
    ModalTypes["QR_MODAL"] = "qrModal";
    ModalTypes["PLAYER_ERROR"] = "playerError";
    ModalTypes["PLAYER_LOADER"] = "playerLoader";
    ModalTypes["ACTIVATION"] = "activation";
    ModalTypes["ACTIVATION_TEMPPASS_MVPD"] = "activationTempPassMvpd";
    ModalTypes["ACTIVATION_TEMPPASS_NBC"] = "activationTempPassNbc";
    ModalTypes["TEMPPASS_CONCLUDED"] = "tempPassConcluded";
})(ModalTypes || (ModalTypes = {}));
export var CloseReason;
(function (CloseReason) {
    CloseReason["CANCELLED"] = "cancelled";
    CloseReason["COMPLETED"] = "completed";
    CloseReason["DEFAULT"] = "default";
})(CloseReason || (CloseReason = {}));
export var ModalManagerEventTypes;
(function (ModalManagerEventTypes) {
    ModalManagerEventTypes["CLOSE"] = "close";
    ModalManagerEventTypes["OPEN"] = "open";
    ModalManagerEventTypes["UPDATE"] = "update";
})(ModalManagerEventTypes || (ModalManagerEventTypes = {}));
const createModalManagerEvent = (type, event, payload) => ({ event, type, payload });
class ModalManagerClass {
    constructor() {
        this._subject = new Subject();
    }
    subscribe(type, callback) {
        return this._subject.pipe(filter((e) => (e === null || e === void 0 ? void 0 : e.type) === type)).subscribe(callback);
    }
    close(closeReason = CloseReason.DEFAULT) {
        if (!this._activeModal)
            return;
        const previousActiveModal = this._activeModal;
        this._activeModal = undefined;
        this._subject.next(createModalManagerEvent(previousActiveModal, ModalManagerEventTypes.CLOSE, closeReason));
    }
    open(type, data) {
        if (this._activeModal)
            this.close(CloseReason.CANCELLED);
        this._activeModal = type;
        this._subject.next(createModalManagerEvent(type, ModalManagerEventTypes.OPEN, data));
    }
    update(type, data, force = false) {
        if (!this._activeModal && !force)
            return;
        this._subject.next(createModalManagerEvent(type, ModalManagerEventTypes.UPDATE, data));
    }
    getActiveModal() {
        return this._activeModal;
    }
}
const ModalManager = new ModalManagerClass();
export const isBlockingModalOpen = () => [
    ModalTypes.ACTIVATION,
    ModalTypes.ACTIVATION_TEMPPASS_MVPD,
    ModalTypes.ACTIVATION_TEMPPASS_NBC,
    ModalTypes.ACTIVATION_TEMPPASS_NBC,
    ModalTypes.TEMPPASS_CONCLUDED,
    ModalTypes.PLAYER_ERROR,
].includes(ModalManager._activeModal);
export default ModalManager;
