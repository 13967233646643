import gql from 'graphql-tag';
export var AudioLanguages;
(function (AudioLanguages) {
    AudioLanguages["Ad"] = "ad";
    AudioLanguages["En"] = "en";
    AudioLanguages["Es"] = "es";
    AudioLanguages["Nat"] = "nat";
})(AudioLanguages || (AudioLanguages = {}));
/** The target destination for a CTA. */
export var DestinationType;
(function (DestinationType) {
    DestinationType["ExternalAppLink"] = "externalAppLink";
    DestinationType["ExternalPage"] = "externalPage";
    DestinationType["InternalPage"] = "internalPage";
    DestinationType["JumpTo"] = "jumpTo";
    DestinationType["Movie"] = "movie";
    DestinationType["Playlist"] = "playlist";
    DestinationType["Series"] = "series";
    DestinationType["SingleEvent"] = "singleEvent";
    DestinationType["Trial"] = "trial";
    DestinationType["Video"] = "video";
    DestinationType["Vote"] = "vote";
})(DestinationType || (DestinationType = {}));
export var EndCardEntityTypes;
(function (EndCardEntityTypes) {
    EndCardEntityTypes["Episodes"] = "episodes";
    EndCardEntityTypes["MovieFullVideos"] = "movieFullVideos";
    EndCardEntityTypes["Movies"] = "movies";
    EndCardEntityTypes["Segments"] = "segments";
    EndCardEntityTypes["Series"] = "series";
})(EndCardEntityTypes || (EndCardEntityTypes = {}));
export var EndCardLogic;
(function (EndCardLogic) {
    EndCardLogic["Binge"] = "binge";
    EndCardLogic["CognitiveScale"] = "cognitiveScale";
    EndCardLogic["Collection"] = "collection";
    EndCardLogic["CompanionContent"] = "companionContent";
    EndCardLogic["Default"] = "default";
    EndCardLogic["DeportesPrimaryCategory"] = "deportesPrimaryCategory";
    EndCardLogic["DeportesSecondaryCategory"] = "deportesSecondaryCategory";
    EndCardLogic["EditorsChoiceSmartTile"] = "editorsChoiceSmartTile";
    EndCardLogic["FavoritesFirst"] = "favoritesFirst";
    EndCardLogic["FavoritesNext"] = "favoritesNext";
    EndCardLogic["NeuralNet"] = "neuralNet";
    EndCardLogic["NeuralNetSmartTile"] = "neuralNetSmartTile";
    EndCardLogic["NextEpisode"] = "nextEpisode";
    EndCardLogic["Override"] = "override";
    EndCardLogic["ParallelSmartTile"] = "parallelSmartTile";
    EndCardLogic["Playlist"] = "playlist";
    EndCardLogic["PreviousSegment"] = "previousSegment";
    EndCardLogic["Recommendation"] = "recommendation";
    EndCardLogic["RecommendationSmartTile"] = "recommendationSmartTile";
    EndCardLogic["SimilarTitle"] = "similarTitle";
    EndCardLogic["SimilarTitleSmartTile"] = "similarTitleSmartTile";
    EndCardLogic["SportsDisplayGenre"] = "sportsDisplayGenre";
    EndCardLogic["SportsSecondaryCategory"] = "sportsSecondaryCategory";
    EndCardLogic["TrailerToTitleStart"] = "trailerToTitleStart";
})(EndCardLogic || (EndCardLogic = {}));
export var Entitlement;
(function (Entitlement) {
    Entitlement["Auth"] = "auth";
    Entitlement["Free"] = "free";
})(Entitlement || (Entitlement = {}));
export var EntitlementWindowDevices;
(function (EntitlementWindowDevices) {
    EntitlementWindowDevices["ConnectedTvDevice"] = "ConnectedTvDevice";
    EntitlementWindowDevices["MobileApp"] = "MobileApp";
    EntitlementWindowDevices["Web"] = "Web";
})(EntitlementWindowDevices || (EntitlementWindowDevices = {}));
export var EntitlementWindowEntitlements;
(function (EntitlementWindowEntitlements) {
    EntitlementWindowEntitlements["Auth"] = "auth";
    EntitlementWindowEntitlements["Free"] = "free";
})(EntitlementWindowEntitlements || (EntitlementWindowEntitlements = {}));
/** The type of page a Page describes. */
export var EntityPageType;
(function (EntityPageType) {
    EntityPageType["BrandLanding"] = "BRAND_LANDING";
    EntityPageType["Browse"] = "BROWSE";
    EntityPageType["LiveSchedule"] = "LIVE_SCHEDULE";
    EntityPageType["OlympicsContentHub"] = "OLYMPICS_CONTENT_HUB";
    EntityPageType["OlympicsMedals"] = "OLYMPICS_MEDALS";
    EntityPageType["OlympicsPage"] = "OLYMPICS_PAGE";
    EntityPageType["OlympicsQrModal"] = "OLYMPICS_QR_MODAL";
    EntityPageType["OlympicsVideo"] = "OLYMPICS_VIDEO";
    EntityPageType["Page"] = "PAGE";
    EntityPageType["Playlist"] = "PLAYLIST";
    EntityPageType["Schedule"] = "SCHEDULE";
    EntityPageType["Series"] = "SERIES";
    EntityPageType["SportLanding"] = "SPORT_LANDING";
    EntityPageType["Stream"] = "STREAM";
    EntityPageType["Title"] = "TITLE";
    EntityPageType["TitleV2"] = "TITLE_V2";
    EntityPageType["TitleV2About"] = "TITLE_V2_ABOUT";
    EntityPageType["TitleV2Bio"] = "TITLE_V2_BIO";
    EntityPageType["TitleV2Cast"] = "TITLE_V2_CAST";
    EntityPageType["TitleV2Contestants"] = "TITLE_V2_CONTESTANTS";
    EntityPageType["TitleV2Episodes"] = "TITLE_V2_EPISODES";
    EntityPageType["TitleV2More"] = "TITLE_V2_MORE";
    EntityPageType["Video"] = "VIDEO";
})(EntityPageType || (EntityPageType = {}));
export var FeatureItemDestinationTypes;
(function (FeatureItemDestinationTypes) {
    FeatureItemDestinationTypes["Link"] = "link";
    FeatureItemDestinationTypes["Playlist"] = "playlist";
    FeatureItemDestinationTypes["Video"] = "video";
})(FeatureItemDestinationTypes || (FeatureItemDestinationTypes = {}));
/** HTTP methods according to RFC 2616. See https://www.w3.org/Protocols/rfc2616/rfc2616-sec9.html */
export var Http_Methods;
(function (Http_Methods) {
    Http_Methods["Connect"] = "CONNECT";
    Http_Methods["Delete"] = "DELETE";
    Http_Methods["Get"] = "GET";
    Http_Methods["Head"] = "HEAD";
    Http_Methods["Options"] = "OPTIONS";
    Http_Methods["Patch"] = "PATCH";
    Http_Methods["Post"] = "POST";
    Http_Methods["Put"] = "PUT";
    Http_Methods["Trace"] = "TRACE";
})(Http_Methods || (Http_Methods = {}));
export var ImageFeature;
(function (ImageFeature) {
    ImageFeature["Fork"] = "fork";
    ImageFeature["Identity"] = "identity";
    ImageFeature["Onboarding"] = "onboarding";
})(ImageFeature || (ImageFeature = {}));
export var Languages;
(function (Languages) {
    Languages["En"] = "en";
    Languages["Es"] = "es";
})(Languages || (Languages = {}));
export var LazyShelfQueryNames;
(function (LazyShelfQueryNames) {
    LazyShelfQueryNames["BrandSeriesGroupedByCategory"] = "brandSeriesGroupedByCategory";
    LazyShelfQueryNames["BrandTitleCategories"] = "brandTitleCategories";
    LazyShelfQueryNames["ComponentsForPlaceholders"] = "componentsForPlaceholders";
    LazyShelfQueryNames["ComponentsForPlaceholdersCached"] = "componentsForPlaceholders_cached";
    LazyShelfQueryNames["DaySchedule"] = "daySchedule";
    LazyShelfQueryNames["EndCard"] = "endCard";
    LazyShelfQueryNames["EndCardCached"] = "endCard_cached";
    LazyShelfQueryNames["MixedEditorialShelf"] = "mixedEditorialShelf";
    LazyShelfQueryNames["OnAirNowShelf"] = "onAirNowShelf";
    LazyShelfQueryNames["PaginatedComponent"] = "paginatedComponent";
    LazyShelfQueryNames["PaginatedShelf"] = "paginatedShelf";
    LazyShelfQueryNames["ScrollFromSeason"] = "scrollFromSeason";
    LazyShelfQueryNames["SeasonShelfGroup"] = "seasonShelfGroup";
    LazyShelfQueryNames["VideosSection"] = "videosSection";
})(LazyShelfQueryNames || (LazyShelfQueryNames = {}));
export var LiveContentType;
(function (LiveContentType) {
    LiveContentType["Linear"] = "LINEAR";
    LiveContentType["Sle"] = "SLE";
})(LiveContentType || (LiveContentType = {}));
export var NbcuBrands;
(function (NbcuBrands) {
    NbcuBrands["Bravo"] = "bravo";
    NbcuBrands["Cnbc"] = "cnbc";
    NbcuBrands["E"] = "e";
    NbcuBrands["Golf"] = "golf";
    NbcuBrands["KellyClarkson"] = "kellyClarkson";
    NbcuBrands["Msnbc"] = "msnbc";
    NbcuBrands["Nbc"] = "nbc";
    NbcuBrands["NbcNews"] = "nbcNews";
    NbcuBrands["NbcSports"] = "nbcSports";
    NbcuBrands["NbcUniverso"] = "nbcUniverso";
    NbcuBrands["Necn"] = "necn";
    NbcuBrands["Oxygen"] = "oxygen";
    NbcuBrands["Peacock"] = "peacock";
    NbcuBrands["Syfy"] = "syfy";
    NbcuBrands["Telemundo"] = "telemundo";
    NbcuBrands["TelemundoDeportes"] = "telemundoDeportes";
    NbcuBrands["UniversalKids"] = "universalKids";
    NbcuBrands["Usa"] = "usa";
})(NbcuBrands || (NbcuBrands = {}));
export var NbcuBrandsPlusAll;
(function (NbcuBrandsPlusAll) {
    NbcuBrandsPlusAll["All"] = "all";
    NbcuBrandsPlusAll["Bravo"] = "bravo";
    NbcuBrandsPlusAll["Cnbc"] = "cnbc";
    NbcuBrandsPlusAll["E"] = "e";
    NbcuBrandsPlusAll["Golf"] = "golf";
    NbcuBrandsPlusAll["KellyClarkson"] = "kellyClarkson";
    NbcuBrandsPlusAll["Msnbc"] = "msnbc";
    NbcuBrandsPlusAll["Nbc"] = "nbc";
    NbcuBrandsPlusAll["NbcNews"] = "nbcNews";
    NbcuBrandsPlusAll["NbcSports"] = "nbcSports";
    NbcuBrandsPlusAll["NbcUniverso"] = "nbcUniverso";
    NbcuBrandsPlusAll["Necn"] = "necn";
    NbcuBrandsPlusAll["Oxygen"] = "oxygen";
    NbcuBrandsPlusAll["Peacock"] = "peacock";
    NbcuBrandsPlusAll["Syfy"] = "syfy";
    NbcuBrandsPlusAll["Telemundo"] = "telemundo";
    NbcuBrandsPlusAll["TelemundoDeportes"] = "telemundoDeportes";
    NbcuBrandsPlusAll["UniversalKids"] = "universalKids";
    NbcuBrandsPlusAll["Usa"] = "usa";
})(NbcuBrandsPlusAll || (NbcuBrandsPlusAll = {}));
/** The target destination for a NavigationItem. */
export var NavigationItemDestinationType;
(function (NavigationItemDestinationType) {
    NavigationItemDestinationType["ExternalPage"] = "externalPage";
    NavigationItemDestinationType["InternalPage"] = "internalPage";
})(NavigationItemDestinationType || (NavigationItemDestinationType = {}));
export var NetworkName;
(function (NetworkName) {
    NetworkName["Email"] = "email";
    NetworkName["Facebook"] = "facebook";
    NetworkName["Googleplus"] = "googleplus";
    NetworkName["Instagram"] = "instagram";
    NetworkName["Pinterest"] = "pinterest";
    NetworkName["Snapchat"] = "snapchat";
    NetworkName["Spotify"] = "spotify";
    NetworkName["Tiktok"] = "tiktok";
    NetworkName["Tumblr"] = "tumblr";
    NetworkName["Twitter"] = "twitter";
    NetworkName["Vine"] = "vine";
    NetworkName["Youtube"] = "youtube";
})(NetworkName || (NetworkName = {}));
export var NielsenProgen;
(function (NielsenProgen) {
    NielsenProgen["A"] = "A";
    NielsenProgen["Ap"] = "AP";
    NielsenProgen["Cc"] = "CC";
    NielsenProgen["Cp"] = "CP";
    NielsenProgen["Cs"] = "CS";
    NielsenProgen["Dd"] = "DD";
    NielsenProgen["Do"] = "DO";
    NielsenProgen["Ea"] = "EA";
    NielsenProgen["Gd"] = "GD";
    NielsenProgen["Gv"] = "GV";
    NielsenProgen["Ia"] = "IA";
    NielsenProgen["N"] = "N";
    NielsenProgen["Op"] = "OP";
    NielsenProgen["Pm"] = "PM";
    NielsenProgen["Se"] = "SE";
    NielsenProgen["Sf"] = "SF";
    NielsenProgen["Sm"] = "SM";
})(NielsenProgen || (NielsenProgen = {}));
/** The types of placeholders (grey grids) clients could potentially load while fetching content. */
export var PlaceholderTypes;
(function (PlaceholderTypes) {
    PlaceholderTypes["Grid"] = "Grid";
    PlaceholderTypes["GroupedContinuousScroll"] = "GroupedContinuousScroll";
    PlaceholderTypes["Guide"] = "Guide";
    PlaceholderTypes["LiveAndUpcoming"] = "LiveAndUpcoming";
    PlaceholderTypes["Olympics"] = "Olympics";
    PlaceholderTypes["OnAirNowShelf"] = "OnAirNowShelf";
    PlaceholderTypes["Shelf"] = "Shelf";
    PlaceholderTypes["SlideShow"] = "SlideShow";
    PlaceholderTypes["Stack"] = "Stack";
    PlaceholderTypes["StackGroup"] = "StackGroup";
    PlaceholderTypes["TvGuide"] = "TvGuide";
})(PlaceholderTypes || (PlaceholderTypes = {}));
export var PopularTitlesBrand;
(function (PopularTitlesBrand) {
    PopularTitlesBrand["Bravo"] = "bravo";
    PopularTitlesBrand["Cnbc"] = "cnbc";
    PopularTitlesBrand["E"] = "e";
    PopularTitlesBrand["Msnbc"] = "msnbc";
    PopularTitlesBrand["Nbc"] = "nbc";
    PopularTitlesBrand["NbcUniverso"] = "nbcUniverso";
    PopularTitlesBrand["Oxygen"] = "oxygen";
    PopularTitlesBrand["Peacock"] = "peacock";
    PopularTitlesBrand["Syfy"] = "syfy";
    PopularTitlesBrand["Telemundo"] = "telemundo";
    PopularTitlesBrand["UniversalKids"] = "universalKids";
    PopularTitlesBrand["Usa"] = "usa";
})(PopularTitlesBrand || (PopularTitlesBrand = {}));
export var PopularTitlesCategory;
(function (PopularTitlesCategory) {
    PopularTitlesCategory["Classic"] = "classic";
})(PopularTitlesCategory || (PopularTitlesCategory = {}));
export var PopularTitlesEntityType;
(function (PopularTitlesEntityType) {
    PopularTitlesEntityType["Movies"] = "movies";
    PopularTitlesEntityType["Series"] = "series";
})(PopularTitlesEntityType || (PopularTitlesEntityType = {}));
export var PopularTitlesGenre;
(function (PopularTitlesGenre) {
    PopularTitlesGenre["ActionAndAdventure"] = "actionAndAdventure";
    PopularTitlesGenre["Animation"] = "animation";
    PopularTitlesGenre["CelebrityAndGossip"] = "celebrityAndGossip";
    PopularTitlesGenre["Comedy"] = "comedy";
    PopularTitlesGenre["CrimeAndMystery"] = "crimeAndMystery";
    PopularTitlesGenre["DocumentaryAndBiography"] = "documentaryAndBiography";
    PopularTitlesGenre["Drama"] = "drama";
    PopularTitlesGenre["Entertainment"] = "entertainment";
    PopularTitlesGenre["FamilyAndKids"] = "familyAndKids";
    PopularTitlesGenre["Gaming"] = "gaming";
    PopularTitlesGenre["HorrorAndThriller"] = "horrorAndThriller";
    PopularTitlesGenre["HouseAndHome"] = "houseAndHome";
    PopularTitlesGenre["Latenight"] = "latenight";
    PopularTitlesGenre["LifestyleAndFashion"] = "lifestyleAndFashion";
    PopularTitlesGenre["LiveEventsAndSpecials"] = "liveEventsAndSpecials";
    PopularTitlesGenre["Medical"] = "medical";
    PopularTitlesGenre["Music"] = "music";
    PopularTitlesGenre["NewsAndInformation"] = "newsAndInformation";
    PopularTitlesGenre["None"] = "none";
    PopularTitlesGenre["Political"] = "political";
    PopularTitlesGenre["Reality"] = "reality";
    PopularTitlesGenre["RealityAndGameShow"] = "realityAndGameShow";
    PopularTitlesGenre["Relationship"] = "relationship";
    PopularTitlesGenre["SciFiAndFantasy"] = "sciFiAndFantasy";
    PopularTitlesGenre["SoapOpera"] = "soapOpera";
    PopularTitlesGenre["Special"] = "special";
    PopularTitlesGenre["Sports"] = "sports";
    PopularTitlesGenre["SportsAndFitness"] = "sportsAndFitness";
    PopularTitlesGenre["TalkAndInterview"] = "talkAndInterview";
    PopularTitlesGenre["Technology"] = "technology";
    PopularTitlesGenre["TravelAndNature"] = "travelAndNature";
    PopularTitlesGenre["Variety"] = "variety";
})(PopularTitlesGenre || (PopularTitlesGenre = {}));
export var PopularTitlesPeriod;
(function (PopularTitlesPeriod) {
    PopularTitlesPeriod["1Day"] = "_1Day";
    PopularTitlesPeriod["1Hour"] = "_1Hour";
    PopularTitlesPeriod["3Hour"] = "_3Hour";
    PopularTitlesPeriod["6Hour"] = "_6Hour";
    PopularTitlesPeriod["7Day"] = "_7Day";
    PopularTitlesPeriod["28Day"] = "_28Day";
})(PopularTitlesPeriod || (PopularTitlesPeriod = {}));
export var QueryNames;
(function (QueryNames) {
    QueryNames["BecauseYouWatchedShelf"] = "becauseYouWatchedShelf";
    QueryNames["BonanzaPage"] = "bonanzaPage";
    QueryNames["BrandSeriesGroupedByCategory"] = "brandSeriesGroupedByCategory";
    QueryNames["BrandTitleCategories"] = "brandTitleCategories";
    QueryNames["ComponentsForPlaceholders"] = "componentsForPlaceholders";
    QueryNames["ComponentsForPlaceholdersCached"] = "componentsForPlaceholders_cached";
    QueryNames["ContinueWatchingShelf"] = "continueWatchingShelf";
    QueryNames["DaySchedule"] = "daySchedule";
    QueryNames["EditorialShelf"] = "editorialShelf";
    QueryNames["EndCard"] = "endCard";
    QueryNames["EndCardCached"] = "endCard_cached";
    QueryNames["FavoriteInteractions"] = "favoriteInteractions";
    QueryNames["FavoritesShelf"] = "favoritesShelf";
    QueryNames["FeaturedSection"] = "featuredSection";
    QueryNames["FeaturedSectionCached"] = "featuredSection_cached";
    QueryNames["FeaturedShowsSection"] = "featuredShowsSection";
    QueryNames["GlobalNavigation"] = "globalNavigation";
    QueryNames["LdStateFlag"] = "ldStateFlag";
    QueryNames["MedalsWidgetEmbedCached"] = "medalsWidgetEmbed_cached";
    QueryNames["MixedEditorialShelf"] = "mixedEditorialShelf";
    QueryNames["NeuralNetShelf"] = "neuralNetShelf";
    QueryNames["NewEpisodesAvailableShelf"] = "newEpisodesAvailableShelf";
    QueryNames["OnAirNowShelf"] = "onAirNowShelf";
    QueryNames["OnboardingBrandCategories"] = "onboardingBrandCategories";
    QueryNames["OnboardingCategoryFavorites"] = "onboardingCategoryFavorites";
    QueryNames["Page"] = "page";
    QueryNames["PaginatedComponent"] = "paginatedComponent";
    QueryNames["PaginatedShelf"] = "paginatedShelf";
    QueryNames["PopularTitlesShelf"] = "popularTitlesShelf";
    QueryNames["RecentEpisodes"] = "recentEpisodes";
    QueryNames["RecentlyUnlockedShelf"] = "recentlyUnlockedShelf";
    QueryNames["ResultsWidgetEmbedCached"] = "resultsWidgetEmbed_cached";
    QueryNames["ScheduleWidgetEmbedCached"] = "scheduleWidgetEmbed_cached";
    QueryNames["ScrollFromSeason"] = "scrollFromSeason";
    QueryNames["SearchShelf"] = "searchShelf";
    QueryNames["SeasonShelfGroup"] = "seasonShelfGroup";
    QueryNames["TitleV2AboutSection"] = "titleV2AboutSection";
    QueryNames["TitleV2HomeSections"] = "titleV2HomeSections";
    QueryNames["TitleV2MoreSections"] = "titleV2MoreSections";
    QueryNames["UserFavorites"] = "userFavorites";
    QueryNames["UserInteractions"] = "userInteractions";
    QueryNames["UserLiveWatches"] = "userLiveWatches";
    QueryNames["UserWatches"] = "userWatches";
    QueryNames["VideosSection"] = "videosSection";
})(QueryNames || (QueryNames = {}));
/** Names to identify the primary business logic powering the fetching and assembling of page sections. */
export var SectionLogic;
(function (SectionLogic) {
    SectionLogic["ContinueWatchingShelf"] = "continueWatchingShelf";
    SectionLogic["FavoritesShelf"] = "favoritesShelf";
    SectionLogic["SmarterContinueWatchingShelf"] = "smarterContinueWatchingShelf";
    SectionLogic["SportsMoreClips"] = "sportsMoreClips";
    SectionLogic["TrendingNow"] = "trendingNow";
})(SectionLogic || (SectionLogic = {}));
/** The platforms supported by Friendship. */
export var SupportedPlatforms;
(function (SupportedPlatforms) {
    SupportedPlatforms["Android"] = "android";
    SupportedPlatforms["AndroidTv"] = "androidTv";
    SupportedPlatforms["Chromecast"] = "chromecast";
    SupportedPlatforms["FireTablet"] = "fireTablet";
    SupportedPlatforms["FireTv"] = "fireTv";
    SupportedPlatforms["FireTvStick"] = "fireTvStick";
    SupportedPlatforms["FireTvLightning"] = "fireTvLightning";
    SupportedPlatforms["Flex"] = "flex";
    SupportedPlatforms["IOs"] = "iOS";
    SupportedPlatforms["MobileWeb"] = "mobileWeb";
    SupportedPlatforms["Platco"] = "platco";
    SupportedPlatforms["Portal"] = "portal";
    SupportedPlatforms["PortalTablet"] = "portalTablet";
    SupportedPlatforms["PortalTv"] = "portalTv";
    SupportedPlatforms["Roku"] = "roku";
    SupportedPlatforms["SamsungTv"] = "samsungTv";
    SupportedPlatforms["TvOs"] = "tvOS";
    SupportedPlatforms["Vizio"] = "vizio";
    SupportedPlatforms["Web"] = "web";
    SupportedPlatforms["WebKellyclarkson"] = "webKellyclarkson";
    SupportedPlatforms["XboxOne"] = "xboxOne";
})(SupportedPlatforms || (SupportedPlatforms = {}));
export var TitleType;
(function (TitleType) {
    TitleType["Movie"] = "movie";
    TitleType["Series"] = "series";
})(TitleType || (TitleType = {}));
/** The types of title pages that can be returned. */
export var TitleV2PageType;
(function (TitleV2PageType) {
    TitleV2PageType["Movie"] = "movie";
    TitleV2PageType["Series"] = "series";
    TitleV2PageType["TitleHub"] = "titleHub";
})(TitleV2PageType || (TitleV2PageType = {}));
export var VideoAnalyticsEntitlement;
(function (VideoAnalyticsEntitlement) {
    VideoAnalyticsEntitlement["Entitled"] = "Entitled";
    VideoAnalyticsEntitlement["Free"] = "Free";
})(VideoAnalyticsEntitlement || (VideoAnalyticsEntitlement = {}));
export var VideoType;
(function (VideoType) {
    VideoType["Episode"] = "episode";
    VideoType["MovieFullVideo"] = "movieFullVideo";
    VideoType["MovieSegment"] = "movieSegment";
    VideoType["Segment"] = "segment";
    VideoType["SingleLiveEvents"] = "singleLiveEvents";
    VideoType["SportsVideo"] = "sportsVideo";
})(VideoType || (VideoType = {}));
export var XyFallback;
(function (XyFallback) {
    XyFallback["None"] = "None";
    XyFallback["X"] = "X";
    XyFallback["Y"] = "Y";
})(XyFallback || (XyFallback = {}));
export const Component = gql `
    fragment component on Component {
  component
  meta
  treatments
}
    `;
export const Section = gql `
    fragment section on Section {
  logicName
  deepLinkHandle
}
    `;
export const ComponentData = gql `
    fragment componentData on ComponentData {
  instanceID
}
    `;
export const BrandTitleCategoriesItem = gql `
    fragment brandTitleCategoriesItem on Item {
  v4ID
  title
  secondaryTitle
  description
  image
  gradientStart
  gradientEnd
  labelBadge
  lastModified
}
    `;
export const BrandTitleCategoriesSeriesItem = gql `
    fragment brandTitleCategoriesSeriesItem on SeriesItem {
  ...componentData
  ...brandTitleCategoriesItem
  seriesName
  shortTitle
  urlAlias
  favoritedOn
  favoriteID
  lightPrimaryColor
  darkPrimaryColor
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  landscapePreview
  portraitPreview
  posterImage
  ariaLabel
  isTrending
}
    `;
export const BrandTitleCategoriesSeriesTile = gql `
    fragment brandTitleCategoriesSeriesTile on SeriesTile {
  ...component
  data {
    ...brandTitleCategoriesSeriesItem
  }
  analytics {
    isTrending
    series
    brand {
      title
    }
    genre
  }
}
    `;
export const BrandTitleCategoriesMovieItem = gql `
    fragment brandTitleCategoriesMovieItem on MovieItem {
  ...componentData
  ...brandTitleCategoriesItem
  urlAlias
  favoritedOn
  favoriteID
  posterImage
  image
  lightPrimaryColor
  darkPrimaryColor
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  landscapePreview
  portraitPreview
  ariaLabel
  isTrending
}
    `;
export const BrandTitleCategoriesMovieTile = gql `
    fragment brandTitleCategoriesMovieTile on MovieTile {
  ...component
  data {
    ...brandTitleCategoriesMovieItem
  }
  analytics {
    isTrending
    movie
    brand {
      title
    }
    genre
  }
}
    `;
export const LazyComponent = gql `
    fragment lazyComponent on LazyComponent {
  targetComponent
  data {
    ...componentData
    queryName
    queryVariables
    entryField
    path
  }
}
    `;
export const LazyGrid = gql `
    fragment lazyGrid on LazyGrid {
  ...component
  ...section
  ...lazyComponent
}
    `;
export const Image = gql `
    fragment image on Image {
  path
  width
  height
  altText
}
    `;
export const QrModalData = gql `
    fragment qrModalData on QRModalData {
  ...componentData
  backgroundImage
  backgroundColor
  logoImage
  qrCodeImage
  qrCodeImageAltText
  description
  ctaText
  dismissText
  streamAccessName
}
    `;
export const QrModal = gql `
    fragment qrModal on QRModal {
  ...component
  data {
    ...qrModalData
  }
  analytics {
    component
    modalName
    modalType
    ctaText
    dismissText
    programTitle
    brand {
      title
    }
  }
}
    `;
export const CtaData = gql `
    fragment ctaData on CTAData {
  ...componentData
  color
  gradientStart
  gradientEnd
  text
  destinationType
  destination
  endCardMpxGuid
  endCardTagLine
  playlistMachineName
  playlistCount
  urlAlias
  isLive
  isPlaylist
  title
  secondaryTitle
  secondaryTitleTag
  isTrailer
  isSportsVideoSlide
  image {
    ...image
  }
  pid
  contentType
  liveEntitlement
  qrModal {
    ...qrModal
  }
}
    `;
export const CtaLink = gql `
    fragment ctaLink on CTALink {
  ...component
  data {
    ...ctaData
  }
  analytics {
    smartDynamicCTA
    smartTileLabel
    smartTileLogic
    editorialLogic
    smartTileScenario
    ctaTitle
    destinationType
    destination
    brand {
      title
    }
    series
    movie
    isMovie
    videoTitle
    locked
    programmingType
    seasonNumber
    episodeNumber
    mpxGuid
    duration
    isPlaylist
    playlistMachineName
    playlistTitle
    isLive
    sponsorName
    isSponsoredTitle
    isTrailer
    liveEntitlement
    isVote
    isSportVideo
    language
    league
    event
    sport
  }
}
    `;
export const BrandTitleCategoriesTileList = gql `
    fragment brandTitleCategoriesTileList on TileList {
  ...componentData
  machineName
  listTitle
  ariaLabel
  lastModified
  items {
    ...brandTitleCategoriesSeriesTile
    ...brandTitleCategoriesMovieTile
  }
  moreItems {
    ...lazyGrid
  }
  viewAllCta {
    ...ctaLink
  }
}
    `;
export const BrandTitleCategoriesGrid = gql `
    fragment brandTitleCategoriesGrid on Grid {
  ...component
  ...section
  data {
    ...brandTitleCategoriesTileList
  }
}
    `;
export const BrandTitleCategoriesStringSelectableComponentList = gql `
    fragment brandTitleCategoriesStringSelectableComponentList on StringSelectableComponentList {
  ...componentData
  initiallySelected
  itemLabels
  itemLabelsTitle
  gradientStart
  optionalTitle: title
  items {
    ...brandTitleCategoriesGrid
  }
}
    `;
export const LinksSelectableGroupAnalyticsAttributes = gql `
    fragment linksSelectableGroupAnalyticsAttributes on LinksSelectableGroupAnalyticsAttributes {
  itemLabels
}
    `;
export const BrandTitleCategoriesLinksSelectableGroup = gql `
    fragment brandTitleCategoriesLinksSelectableGroup on LinksSelectableGroup {
  ...component
  ...section
  data {
    ...brandTitleCategoriesStringSelectableComponentList
  }
  analytics {
    ...linksSelectableGroupAnalyticsAttributes
  }
}
    `;
export const Item = gql `
    fragment item on Item {
  v4ID
  title
  secondaryTitle
  tertiaryTitle
  description
  image
  gradientStart
  gradientEnd
  labelBadge
  lastModified
}
    `;
export const VideoItem = gql `
    fragment videoItem on VideoItem {
  ...componentData
  ...item
  secondaryTitleTag
  locked
  mpxGuid
  programmingType
  episodeNumber
  seasonNumber
  airDate
  permalink
  lastWatched
  duration
  genre
  rating
  lightPrimaryColor
  darkPrimaryColor
  seriesShortTitle
  movieShortTitle
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  mpxAccountId
  mpxAdPolicy
  resourceId
  channelId
  rating
  externalAdvertiserId
  ariaLabel
  longDescription
  ctaText
  ctaTextColor
  brandMachineName
  durationBadge
  isOlympics
}
    `;
export const VideoTile = gql `
    fragment videoTile on VideoTile {
  ...component
  data {
    ...videoItem
  }
  analytics {
    brand {
      title
    }
    series
    title
    programmingType
    episodeNumber
    seasonNumber
    mpxGuid
    locked
    duration
    movie
    genre
    sport
    league
    language
    event
  }
}
    `;
export const SeriesItem = gql `
    fragment seriesItem on SeriesItem {
  ...componentData
  ...item
  seriesName
  shortTitle
  urlAlias
  favoritedOn
  favoriteID
  posterImage
  lightPrimaryColor
  darkPrimaryColor
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  landscapePreview
  portraitPreview
  ariaLabel
  isTrending
}
    `;
export const SeriesTile = gql `
    fragment seriesTile on SeriesTile {
  ...component
  data {
    ...seriesItem
  }
  analytics {
    isTrending
    series
    brand {
      title
    }
    genre
  }
}
    `;
export const MovieItem = gql `
    fragment movieItem on MovieItem {
  ...componentData
  ...item
  urlAlias
  favoritedOn
  favoriteID
  posterImage
  image
  lightPrimaryColor
  darkPrimaryColor
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  landscapePreview
  portraitPreview
  rating
  ariaLabel
  isTrending
}
    `;
export const MovieTile = gql `
    fragment movieTile on MovieTile {
  ...component
  data {
    ...movieItem
  }
  analytics {
    isTrending
    movie
    brand {
      title
    }
    genre
  }
}
    `;
export const UpcomingModalData = gql `
    fragment upcomingModalData on UpcomingModalData {
  machineName
  title
  description
  ctaText
  dismissText
  lastMinuteModalLifespan
  countdownDayLabel
  countdownHourLabel
  countdownMinLabel
  customerPlayableDate
  startTime
  backgroundImage
  backgroundVideo
  resourceId
  channelId
  stationId
  contentType
  pid
  streamAccessName
}
    `;
export const UpcomingModal = gql `
    fragment upcomingModal on UpcomingModal {
  ...component
  data {
    ...upcomingModalData
  }
  analytics {
    modalName
    modalType
    dismissText
    programTitle
    brand {
      title
    }
  }
}
    `;
export const Notification = gql `
    fragment notification on Notification {
  ...component
  data {
    ...componentData
    v4ID
    machineName
    dismissText
    headline
    headlineColor
    message
    messageColor
    logo
    logoAltText
    portraitImage
    landscapeImage
    cta {
      ...ctaLink
    }
  }
  analytics {
    entityTitle
  }
}
    `;
export const UpcomingLiveItem = gql `
    fragment upcomingLiveItem on UpcomingLiveItem {
  instanceID
  v4ID
  machineName
  title
  secondaryTitle
  tertiaryTitle
  shortDescription
  liveBadge
  upcomingBadge
  image
  customerPlayableDate
  startTime
  endTime
  brandV4ID
  whiteBrandLogo
  brandDisplayTitle
  brandLightPrimary
  brandDarkPrimary
  liveAriaLabel
  upcomingAriaLabel
  upcomingModal {
    ...upcomingModal
  }
  resourceId
  channelId
  streamAccessName
  directToLiveThreshold
  stationId
  contentType
  pid
  notification {
    ...notification
  }
  programType
  callSign
  isMedalSession
  audioLanguage
}
    `;
export const UpcomingLiveTile = gql `
    fragment upcomingLiveTile on UpcomingLiveTile {
  ...component
  data {
    ...upcomingLiveItem
  }
  analytics {
    programType
    genre
    secondaryGenre
    listOfGenres
    title
    secondaryTitle
    liveEntitlement
    entitlement
    locked
    league
    sport
    videoBroadcast
    nielsenClientId
    nielsenSfCode
    nielsenChannel
    adobeVideoResearchTitle
    isOlympics
    brand {
      title
    }
  }
}
    `;
export const ReplayTileData = gql `
    fragment replayTileData on ReplayTileData {
  ...componentData
  v4ID
  ariaLabel
  brandDisplayTitle
  colorBrandLogo
  image
  pid
  relativePath
  resourceId
  channelId
  secondaryTitle
  startDate
  title
  whiteBrandLogo
  tertiaryTitle
  labelBadge
  programmingType
  genre
  secondaryGenre
  liveEntitlement
  entitlement
  locked
  isMedalSession
  audioLanguage
}
    `;
export const ReplayTile = gql `
    fragment replayTile on ReplayTile {
  ...component
  replayTileData: data {
    ...replayTileData
  }
  analytics {
    analyticsType
    title
    brand {
      title
    }
    genre
    sport
    league
    event
    programmingType
    secondaryGenre
    listOfGenres
    liveEntitlement
    entitlement
    locked
  }
}
    `;
export const EndCardAlternateData = gql `
    fragment endCardAlternateData on EndCardAlternateData {
  ...componentData
  titleKeyArt
  tagLine
  playlistMachineName
  tile {
    ...videoTile
    ...seriesTile
    ...movieTile
    ...upcomingLiveTile
    ...replayTile
    __typename
  }
  __typename
}
    `;
export const EndCardAnalytics = gql `
    fragment endCardAnalytics on EndCardAnalyticsAttributes {
  brand {
    title
    __typename
  }
  recommendationType
  series
  movie
  title
  programmingType
  episodeNumber
  seasonNumber
  mpxGuid
  locked
  genre
  duration
  playlistMachineName
  endCardVariant
  endCardLogic
  destinationSport
  destinationLeague
}
    `;
export const EndCardAlternate = gql `
    fragment endCardAlternate on EndCardAlternate {
  ...component
  data {
    ...endCardAlternateData
    __typename
  }
  analytics {
    ...endCardAnalytics
    __typename
  }
  __typename
}
    `;
export const EndCardData = gql `
    fragment endCardData on EndCardData {
  ...componentData
  titleTitle
  title
  image
  description
  videoMetaData
  tagLine
  urlAlias
  buttonLabel
  mpxGuid
  permalink
  programmingType
  cuePoint
  locked
  labelBadge
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  mpxAccountId
  mpxAdPolicy
  resourceId
  channelId
  titleLogo
  playlistMachineName
  rating
  alternateGroupTagLine
  alternateOne {
    ...endCardAlternate
    __typename
  }
  alternateTwo {
    ...endCardAlternate
    __typename
  }
  entityType
  v4ID
  gradientEnd
  gradientStart
  lightPrimaryColor
  darkPrimaryColor
  __typename
}
    `;
export const EndCard = gql `
    fragment endCard on EndCard {
  ...component
  data {
    ...endCardData
    __typename
  }
  analytics {
    ...endCardAnalytics
    __typename
  }
  __typename
}
    `;
export const EndTilesData = gql `
    fragment endTilesData on EndTilesData {
  ...componentData
  titleKeyArt
  groupTagLine
  tileOne {
    ...endCardAlternate
    __typename
  }
  tileTwo {
    ...endCardAlternate
    __typename
  }
  __typename
}
    `;
export const EndTiles = gql `
    fragment endTiles on EndTiles {
  ...component
  data {
    ...endTilesData
    __typename
  }
  __typename
}
    `;
export const VideoStoryItem = gql `
    fragment videoStoryItem on VideoStoryItem {
  ...componentData
  v4ID
  playablePublicUrl
  videoID
  titleLogo
  gradientStart
  gradientEnd
  lightPrimaryColor
  darkPrimaryColor
  colorBrandLogo
  whiteBrandLogo
  brandDisplayTitle
  titleKeyArt
  title
  secondaryTitle
  lastModified
  watched
  cta {
    ...ctaLink
  }
}
    `;
export const VideoStoryTile = gql `
    fragment videoStoryTile on VideoStoryTile {
  ...component
  data {
    ...videoStoryItem
  }
  analytics {
    entityTitle
  }
}
    `;
export const BrandItem = gql `
    fragment brandItem on BrandItem {
  ...componentData
  v4ID
  lastModified
  displayTitle
  machineName
  lightPrimaryColor
  darkPrimaryColor
  colorBrandLogo
  whiteBrandLogo
  horizontalPreview
  staticPreviewImage
  ariaLabel
}
    `;
export const BrandTile = gql `
    fragment brandTile on BrandTile {
  ...component
  data {
    ...brandItem
  }
  analytics {
    brand {
      title
    }
  }
}
    `;
export const PersonItem = gql `
    fragment personItem on PersonItem {
  ...componentData
  title
  secondaryTitle
  personImage: image {
    path
  }
  machineName
  roleName
  roleMachineName
  ariaLabel
  titleUrlAlias
  gradientStart
  seasonNumber
}
    `;
export const PersonTile = gql `
    fragment personTile on PersonTile {
  ...component
  data {
    ...personItem
  }
}
    `;
export const FeatureItem = gql `
    fragment featureItem on FeatureItem {
  ...componentData
  ...item
  seriesShortTitle
  image
  destinationType
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  destinationType
  destination
  playlistMachineName
  ariaLabel
}
    `;
export const FeatureTile = gql `
    fragment featureTile on FeatureTile {
  ...component
  data {
    ...featureItem
  }
  analytics {
    series
    brand {
      title
    }
    playlistMachineName
    listTitle
  }
}
    `;
export const PlaylistItem = gql `
    fragment playlistItem on PlaylistItem {
  ...componentData
  ...item
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  destination
  destType: destinationType
  playlistMachineName
  externalAdvertiserId
  playlistCount
  ariaLabel
}
    `;
export const PlaylistTile = gql `
    fragment playlistTile on PlaylistTile {
  ...component
  data {
    ...playlistItem
  }
  analytics {
    brand {
      title
    }
    playlistMachineName
    listTitle
  }
}
    `;
export const MarketingBandData = gql `
    fragment marketingBandData on MarketingBandData {
  ...componentData
  v4ID
  primaryImage
  compactImage
  link
  seriesShortTitle
  lastModified
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  ariaLabel
}
    `;
export const MarketingBand = gql `
    fragment marketingBand on MarketingBand {
  ...component
  data {
    ...marketingBandData
  }
  analytics {
    series
    brand {
      title
    }
  }
}
    `;
export const SmartTileData = gql `
    fragment smartTileData on SmartTileData {
  ...componentData
  label
  tile {
    ...videoTile
  }
}
    `;
export const SmartTile = gql `
    fragment smartTile on SmartTile {
  ...component
  data {
    ...smartTileData
  }
  analytics {
    smartTileLabel
    smartTileLogic
    editorialLogic
    smartTileScenario
    sponsorName
    isSponsoredTitle
  }
}
    `;
export const SlideItem = gql `
    fragment slideItem on SlideItem {
  ...componentData
  ...item
  videoV4ID
  titleColor
  secondaryTitleColor
  description
  descriptionColor
  compactImage
  videoTitle
  episodeNumber
  seasonNumber
  seriesShortTitle
  programmingType
  portraitPreview
  landscapePreview
  titleLogo
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  tuneIn
  resourceId
  rating
  locked
  airDate
  sponsorLogo
  sponsorLogoAltText
  sponsorName
  externalAdvertiserId
  ariaLabel
  slideHeader
  slideHeaderLogo
  isOlympics
  isMedalSession
  language
  cta {
    ...ctaLink
    ...smartTile
  }
}
    `;
export const SlideTile = gql `
    fragment slideTile on SlideTile {
  ...component
  data {
    ...slideItem
  }
  analytics {
    entityTitle
    isSponsoredSlide
    sponsorName
    dynamicallyGenerated
    dynamicGenerationLogic
  }
}
    `;
export const UpcomingLiveSlideTileData = gql `
    fragment upcomingLiveSlideTileData on UpcomingLiveSlideTileData {
  ...componentData
  v4ID
  title
  secondaryTitle
  description
  image
  gradientStart
  gradientEnd
  lastModified
  liveBadge
  upcomingBadge
  titleColor
  secondaryTitleColor
  descriptionColor
  compactImage
  landscapePreview
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  customerPlayableDate
  startTime
  endTime
  liveAriaLabel
  upcomingAriaLabel
  liveCtaText
  upcomingCtaText
  notInPackageCtaText
  resourceId
  channelId
  machineName
  streamAccessName
  directToLiveThreshold
  stationId
  contentType
  pid
  upcomingModal {
    ...upcomingModal
  }
  notification {
    ...notification
  }
  callSign
}
    `;
export const UpcomingLiveSlideTile = gql `
    fragment upcomingLiveSlideTile on UpcomingLiveSlideTile {
  ...component
  data {
    ...upcomingLiveSlideTileData
  }
  analytics {
    analyticsType
    ctaLiveTitle
    ctaUpcomingTitle
    ctaNotInPackageTitle
    isLiveCallout
    programType
    genre
    secondaryGenre
    listOfGenres
    title
    secondaryTitle
    liveEntitlement
    league
    sport
    videoBroadcast
    nielsenClientId
    nielsenChannel
    nielsenSfCode
    isOlympics
    adobeVideoResearchTitle
    brand {
      title
    }
  }
}
    `;
export const OnAirNowItem = gql `
    fragment onAirNowItem on OnAirNowItem {
  ...componentData
  v4ID
  image
  switchToNationalStream
  nationalStreamQueryParameters {
    tags
    homeTeam
    awayTeam
  }
  title
  secondaryTitle
  startTime
  endTime
  brandV4ID
  machineName
  whiteBrandLogo
  brandDisplayTitle
  brandLightPrimary
  brandDarkPrimary
  isNew
  audioDescription
  ratingWithAdvisories
  badge
  resourceId
  channelId
  mpxGuid
  nextEpisodeMpxGuid
  relativePath
  nextEpisodeRelativePath
  watchTagline
  ariaLabel
  streamAccessName
  stationId
  callSign
  contentType
  notification {
    ...notification
  }
}
    `;
export const OnAirNowTile = gql `
    fragment onAirNowTile on OnAirNowTile {
  ...component
  onAirNowTileData: data {
    ...onAirNowItem
  }
  analytics {
    isLive
    episodeNumber
    seasonNumber
    programTitle
    episodeTitle
    tmsId
    liveEntitlement
    adobeVideoResearchTitle
    league
    isOlympics
    sport
    nielsenSfCode
    nielsenChannel
    nielsenClientId
    videoBroadcast
    brand {
      title
    }
  }
}
    `;
export const GenreTile = gql `
    fragment genreTile on GenreTile {
  ...component
  data {
    title
    image
    gradientStart
    gradientEnd
    ctaLink {
      ...ctaLink
    }
    ariaLabel
  }
  analytics {
    itemClickedName
    itemClickedType
    machineName
  }
}
    `;
export const LazyShelf = gql `
    fragment lazyShelf on LazyShelf {
  ...component
  ...section
  ...lazyComponent
}
    `;
export const LazyStack = gql `
    fragment lazyStack on LazyStack {
  ...component
  ...section
  ...lazyComponent
}
    `;
export const TileList = gql `
    fragment tileList on TileList {
  ...componentData
  machineName
  playlistMachineName
  listTitle
  ariaLabel
  listTitleImage
  sponsorLogo
  sponsorName
  sponsorLogoAltText
  lastModified
  items {
    ...videoTile
    ...videoStoryTile
    ...seriesTile
    ...movieTile
    ...brandTile
    ...personTile
    ...featureTile
    ...playlistTile
    ...marketingBand
    ...slideTile
    ...upcomingLiveSlideTile
    ...smartTile
    ...onAirNowTile
    ...genreTile
    ...upcomingLiveTile
    ...replayTile
  }
  moreItems {
    ...lazyShelf
    ...lazyGrid
    ...lazyStack
  }
  viewAllCta {
    ...ctaLink
  }
}
    `;
export const FeaturedSectionShelf = gql `
    fragment featuredSectionShelf on Shelf {
  ...component
  shelfData: data {
    ...tileList
  }
  analytics {
    isPlaylist
    playlistMachineName
    listTitle
    isSponsoredContent
    sponsorName
    isMixedTiles
    machineName
    itemsList
  }
}
    `;
export const Error = gql `
    fragment error on Error {
  error
}
    `;
export const MarketingModule = gql `
    fragment marketingModule on MarketingModule {
  ...component
  ...section
  marketingModuleData: data {
    v4ID
    machineName
    description
    descriptionColor
    logo {
      ...image
    }
    logoAltText
    sponsorName
    isSponsored
    sponsorLogo {
      ...image
    }
    sponsorLogoAltText
    mainImage {
      ...image
    }
    mainPreview
    backgroundPreview
    backgroundFallbackImage {
      ...image
    }
    locked
    externalAdvertiserId
    badge
    gradientStart
    gradientEnd
    primaryCTA {
      ...ctaLink
    }
    secondaryCTA {
      ...ctaLink
    }
    ariaLabel
  }
  analytics {
    itemsList
    listTitle
    sponsorName
    machineName
    isSponsoredMarketingModule
    entityTitle
  }
}
    `;
export const AboutData = gql `
    fragment aboutData on AboutData {
  ...componentData
  title
  description
  shortDisplayTitle
  gradientStart
  gradientEnd
  image {
    ...image
  }
  trailerCTA {
    ...ctaLink
  }
  listOfGenres
  rating
  duration
  activeYears
  tuneIn
  socialMedia {
    name
    url
    handle
  }
}
    `;
export const AboutSection = gql `
    fragment aboutSection on AboutSection {
  ...component
  aboutData: data {
    ...aboutData
  }
}
    `;
export const ContinuousScrollData = gql `
    fragment continuousScrollData on ContinuousScrollData {
  ...componentData
  title
  initialItem
  items {
    groupId
    item {
      ...videoTile
    }
  }
  previous {
    ...lazyComponent
  }
  next {
    ...lazyComponent
  }
}
    `;
export const ContinuousScroll = gql `
    fragment continuousScroll on ContinuousScroll {
  ...component
  ...section
  data {
    ...continuousScrollData
  }
}
    `;
export const GroupedContinuousScrollData = gql `
    fragment groupedContinuousScrollData on GroupedContinuousScrollData {
  ...componentData
  title
  groups {
    id
    title
    itemCount
    jumpToGroup {
      ...lazyComponent
    }
  }
  continuousScroll {
    ...continuousScroll
  }
}
    `;
export const GroupedContinuousScroll = gql `
    fragment groupedContinuousScroll on GroupedContinuousScroll {
  ...component
  ...section
  data {
    ...groupedContinuousScrollData
  }
  analytics {
    itemLabels
  }
}
    `;
export const PremiumItem = gql `
    fragment premiumItem on PremiumItem {
  ...componentData
  v4ID
  description
  descriptionColor
  logo {
    ...image
  }
  logoAltText
  popOutImage {
    ...image
  }
  previewStaticImage {
    ...image
  }
  fallbackImage {
    ...image
  }
  backgroundImage {
    ...image
  }
  mainPreview
  locked
  externalAdvertiserId
  gradientStart
  gradientEnd
  ariaLabel
  cta {
    ...ctaLink
    ...smartTile
  }
}
    `;
export const PremiumTile = gql `
    fragment premiumTile on PremiumTile {
  ...component
  data {
    ...premiumItem
  }
  analytics {
    entityTitle
  }
}
    `;
export const PremiumTileList = gql `
    fragment premiumTileList on PremiumTileList {
  ...componentData
  machineName
  listTitle
  listTitleImage
  sponsorLogo
  sponsorLogoAltText
  sponsorName
  lastModified
  ariaLabel
  items {
    ...premiumTile
  }
}
    `;
export const PremiumShelf = gql `
    fragment premiumShelf on PremiumShelf {
  ...component
  ...section
  data {
    ...premiumTileList
  }
  analytics {
    itemsList
    machineName
    listTitle
    isSponsoredContent
    sponsorName
    shelfType
  }
}
    `;
export const OnAirNowList = gql `
    fragment onAirNowList on OnAirNowList {
  ...componentData
  machineName
  listTitle
  ariaLabel
  lastModified
  items {
    ...onAirNowTile
  }
}
    `;
export const OnAirNowShelf = gql `
    fragment onAirNowShelf on OnAirNowShelf {
  ...component
  ...section
  data {
    ...onAirNowList
  }
  analytics {
    itemsList
    machineName
    listTitle
  }
}
    `;
export const LazyOnAirNowShelf = gql `
    fragment lazyOnAirNowShelf on LazyOnAirNowShelf {
  ...component
  ...section
  ...lazyComponent
}
    `;
export const PlaceholderSection = gql `
    fragment placeholderSection on PlaceholderSection {
  ...component
  data {
    ...componentData
    machineName
    queryVariables
    queryName
    entryField
    path
  }
}
    `;
export const MessageData = gql `
    fragment messageData on MessageData {
  ...componentData
  textRow1
  textRow2
  cta {
    ...ctaLink
  }
}
    `;
export const Message = gql `
    fragment message on Message {
  ...component
  ...section
  data {
    ...messageData
  }
}
    `;
export const Grid = gql `
    fragment grid on Grid {
  ...component
  ...section
  data {
    ...tileList
  }
  analytics {
    listTitle
    playlistMachineName
    isSponsoredContent
    sponsorName
  }
}
    `;
export const GridList = gql `
    fragment gridList on GridList {
  ...componentData
  listTitle
  items {
    ...grid
  }
}
    `;
export const GridGroup = gql `
    fragment gridGroup on GridGroup {
  ...component
  ...section
  data {
    ...gridList
  }
}
    `;
export const ItemLabelsConfigItem = gql `
    fragment itemLabelsConfigItem on ItemLabelsConfigItem {
  itemLabel
  menuItemType
  index
  isSelected
}
    `;
export const Shelf = gql `
    fragment shelf on Shelf {
  ...component
  ...section
  data {
    ...tileList
  }
  analytics {
    isPlaylist
    playlistMachineName
    listTitle
    isSponsoredContent
    sponsorName
    isMixedTiles
    machineName
    itemsList
  }
}
    `;
export const ShelfList = gql `
    fragment shelfList on ShelfList {
  ...componentData
  listTitle
  items {
    ...shelf
  }
}
    `;
export const ShelfGroup = gql `
    fragment shelfGroup on ShelfGroup {
  ...component
  ...section
  data {
    ...shelfList
  }
}
    `;
export const LazyShelfGroup = gql `
    fragment lazyShelfGroup on LazyShelfGroup {
  ...component
  ...lazyComponent
  ...section
}
    `;
export const Stack = gql `
    fragment stack on Stack {
  ...component
  ...section
  data {
    ...tileList
  }
  analytics {
    playlistMachineName
    listTitle
    isSponsoredContent
    sponsorName
  }
}
    `;
export const Placeholder = gql `
    fragment placeholder on Placeholder {
  ...component
  data {
    ...componentData
    machineName
    queryVariables
    queryName
    entryField
    path
  }
}
    `;
export const NestedStringSelectableComponentList = gql `
    fragment nestedStringSelectableComponentList on NestedStringSelectableComponentList {
  ...componentData
  initiallySelected
  itemLabels
  itemLabelsConfig {
    ...itemLabelsConfigItem
  }
  itemLabelsTitle
  optionalTitle: title
  gradientStart
  items {
    ...shelf
    ...lazyShelf
    ...shelfGroup
    ...lazyShelfGroup
    ...grid
    ...stack
    ...lazyStack
    ...placeholder
  }
}
    `;
export const NestedLinksSelectableGroup = gql `
    fragment nestedLinksSelectableGroup on NestedLinksSelectableGroup {
  ...component
  data {
    ...nestedStringSelectableComponentList
  }
  analytics {
    itemLabels
  }
}
    `;
export const StringSelectableComponentList = gql `
    fragment stringSelectableComponentList on StringSelectableComponentList {
  ...componentData
  initiallySelected
  itemLabels
  itemLabelsConfig {
    ...itemLabelsConfigItem
  }
  itemLabelsTitle
  optionalTitle: title
  gradientStart
  items {
    ...shelf
    ...lazyShelf
    ...shelfGroup
    ...lazyShelfGroup
    ...grid
    ...stack
    ...lazyStack
    ...placeholder
    ...nestedLinksSelectableGroup
  }
}
    `;
export const TabsSelectableGroup = gql `
    fragment tabsSelectableGroup on TabsSelectableGroup {
  ...component
  ...section
  data {
    ...stringSelectableComponentList
  }
}
    `;
export const ScheduleListingData = gql `
    fragment scheduleListingData on ScheduleListingData {
  ...componentData
  v4ID
  programTitle
  programDescription
  ratingWithAdvisories
  startTime
  endTime
  audioDescription
  isNew
  lastModified
  seriesUrlAlias
}
    `;
export const ScheduleListing = gql `
    fragment scheduleListing on ScheduleListing {
  ...component
  data {
    ...scheduleListingData
  }
}
    `;
export const ScheduleListingList = gql `
    fragment scheduleListingList on ScheduleListingList {
  ...componentData
  items {
    ...scheduleListing
  }
}
    `;
export const Schedule = gql `
    fragment schedule on Schedule {
  ...component
  data {
    ...scheduleListingList
  }
}
    `;
export const LazySchedule = gql `
    fragment lazySchedule on LazySchedule {
  ...component
  ...lazyComponent
}
    `;
export const DateSelectableComponentList = gql `
    fragment dateSelectableComponentList on DateSelectableComponentList {
  ...componentData
  initiallySelected
  dateItemLabels {
    day
    date
  }
  title
  items {
    ...schedule
    ...lazySchedule
  }
}
    `;
export const DateTabsSelectableGroup = gql `
    fragment dateTabsSelectableGroup on DateTabsSelectableGroup {
  ...component
  ...section
  data {
    ...dateSelectableComponentList
  }
}
    `;
export const Slide = gql `
    fragment slide on Slide {
  ...component
  data {
    ...slideItem
  }
  analytics {
    entityTitle
    isSponsoredSlide
    sponsorName
    dynamicallyGenerated
    dynamicGenerationLogic
  }
}
    `;
export const CtaSmartTileData = gql `
    fragment ctaSmartTileData on CTASmartTileData {
  ...componentData
  label
  title
  secondaryTitle
  secondaryTitleTag
  description
  locked
  labelBadge
  mpxGuid
  permalink
  gradientStart
  gradientEnd
  programmingType
  lastWatched
  mpxAccountId
  mpxAdPolicy
  resourceId
  channelId
  externalAdvertiserId
}
    `;
export const CtaSmartTile = gql `
    fragment ctaSmartTile on CTASmartTile {
  ...component
  data {
    ...ctaSmartTileData
  }
  analytics {
    brand {
      title
    }
    title
    programmingType
    episodeNumber
    seasonNumber
    mpxGuid
    locked
    duration
    movie
    series
    genre
    smartTileLabel
    smartTileLogic
    editorialLogic
    smartTileScenario
    sponsorName
    isSponsoredTitle
    sport
    league
  }
}
    `;
export const CtaSlideData = gql `
    fragment ctaSlideData on CTASlideData {
  ...componentData
  title
  secondaryTitle
  description
  secondaryDescription
  gradientStart
  gradientEnd
  brandDisplayTitle
  sponsorLogoAltText
  sponsorName
  ariaLabel
  playlistMachineName
  titleLogo {
    ...image
  }
  image {
    ...image
  }
  compactImage {
    ...image
  }
  whiteBrandLogo {
    ...image
  }
  colorBrandLogo {
    ...image
  }
  sponsorLogo {
    ...image
  }
  smartTileCTA {
    ...ctaSmartTile
  }
  primaryCTA {
    ...ctaLink
  }
  secondaryCTA {
    ...ctaLink
  }
}
    `;
export const CtaSlide = gql `
    fragment ctaSlide on CTASlide {
  ...component
  ctaSlideData: data {
    ...ctaSlideData
  }
}
    `;
export const UpcomingLiveSlideData = gql `
    fragment upcomingLiveSlideData on UpcomingLiveSlideData {
  ...componentData
  v4ID
  title
  secondaryTitle
  tertiaryTitle
  description
  image
  gradientStart
  gradientEnd
  lastModified
  liveTuneIn
  upcomingTuneIn
  liveBadge
  titleColor
  secondaryTitleColor
  descriptionColor
  compactImage
  landscapePreview
  titleLogo
  brandDisplayTitle
  whiteBrandLogo
  colorBrandLogo
  sponsorLogo
  sponsorLogoAltText
  sponsorName
  customerPlayableDate
  startTime
  endTime
  liveAriaLabel
  upcomingAriaLabel
  liveCtaColor
  upcomingCtaColor
  liveCtaText
  upcomingCtaText
  notInPackageCtaText
  resourceId
  channelId
  stationId
  contentType
  pid
  machineName
  streamAccessName
  directToLiveThreshold
  callSign
  isMedalSession
  language
  slideHeader
  upcomingModal {
    ...upcomingModal
  }
  notification {
    ...notification
  }
}
    `;
export const UpcomingLiveSlide = gql `
    fragment upcomingLiveSlide on UpcomingLiveSlide {
  ...component
  data {
    ...upcomingLiveSlideData
  }
  analytics {
    analyticsType
    ctaLiveTitle
    ctaUpcomingTitle
    ctaNotInPackageTitle
    isLiveCallout
    isSponsoredSlide
    sponsorName
    programType
    genre
    secondaryGenre
    listOfGenres
    title
    secondaryTitle
    liveEntitlement
    league
    sport
    videoBroadcast
    nielsenClientId
    nielsenChannel
    nielsenSfCode
    isOlympics
    adobeVideoResearchTitle
    brand {
      title
    }
    videoTitle
    pid
  }
}
    `;
export const SlideList = gql `
    fragment slideList on SlideList {
  ...componentData
  lastModified
  machineName
  items {
    ...slide
    ...ctaSlide
    ...upcomingLiveSlide
  }
}
    `;
export const Slideshow = gql `
    fragment slideshow on Slideshow {
  ...component
  ...section
  data {
    ...slideList
  }
  analytics {
    itemsList
    machineName
    listTitle
  }
}
    `;
export const HypermediaLink = gql `
    fragment hypermediaLink on HypermediaLink {
  linkTitle
  rel
  request {
    location
    method
    body
    headers {
      name
      value
    }
  }
}
    `;
export const HeroData = gql `
    fragment heroData on HeroData {
  ...componentData
  title
  secondaryTitle
  image
  compactImage
  favoriteID
  favoriteInteraction {
    default {
      ...hypermediaLink
    }
    undo {
      ...hypermediaLink
    }
  }
  smartTile {
    ...smartTile
  }
}
    `;
export const Hero = gql `
    fragment hero on Hero {
  ...component
  ...section
  data {
    ...heroData
  }
}
    `;
export const DescriptionData = gql `
    fragment descriptionData on DescriptionData {
  ...componentData
  optionalTitle
  description
  image
  shortTitle
  gradientStart
}
    `;
export const DescriptionSection = gql `
    fragment descriptionSection on DescriptionSection {
  ...component
  data {
    ...descriptionData
  }
}
    `;
export const OverlayData = gql `
    fragment overlayData on OverlayData {
  content {
    ...descriptionSection
  }
}
    `;
export const Overlay = gql `
    fragment overlay on Overlay {
  ...component
  data {
    ...overlayData
  }
}
    `;
export const CtaHeroData = gql `
    fragment ctaHeroData on CTAHeroData {
  ...componentData
  title
  titleLogo {
    ...image
  }
  gradientStart
  gradientEnd
  description
  secondaryDescription
  heroImage: image {
    ...image
  }
  heroCompactImage: compactImage {
    ...image
  }
  favoriteInteraction {
    default {
      ...hypermediaLink
    }
    undo {
      ...hypermediaLink
    }
  }
  sponsorLogo {
    ...image
  }
  sponsorLogoAltText
  sponsorName
  brandDisplayTitle
  colorBrandLogo {
    ...image
  }
  whiteBrandLogo {
    ...image
  }
  smartTileCTA {
    ...ctaSmartTile
  }
  aboutOverlay {
    ...overlay
  }
  primaryCTA {
    ...ctaLink
  }
  secondaryCTA {
    ...ctaLink
  }
}
    `;
export const CtaHero = gql `
    fragment ctaHero on CTAHero {
  ...component
  ...section
  data {
    ...ctaHeroData
  }
}
    `;
export const NavigationMenu = gql `
    fragment navigationMenu on NavigationMenu {
  ...component
  ...section
  data {
    ...componentData
    favoriteInteraction {
      default {
        ...hypermediaLink
      }
      undo {
        ...hypermediaLink
      }
    }
    gradientStart
    gradientEnd
    brandLightPrimaryColor
    brandDarkPrimaryColor
    shortDisplayTitle
    shortTitle
    multiPlatformLargeImage
    whiteBrandLogo
    tuneIn
    links {
      ...component
      data {
        ...componentData
        items {
          title
          href
          isCoppaCompliant
          target
        }
      }
    }
  }
}
    `;
export const ExpiredVideo = gql `
    fragment expiredVideo on VideoDetailsExpired {
  ...component
  ...section
  data {
    ...componentData
    videoMeta {
      title
      secondaryTitle
      description
      image
    }
  }
}
    `;
export const LazyEndCard = gql `
    fragment lazyEndCard on LazyEndCard {
  ...component
  ...lazyComponent
}
    `;
export const CoreSdkPlayer = gql `
    fragment coreSDKPlayer on CoreSDKPlayer {
  ...component
  ...section
  CoreSDKPlayerData: data {
    ...componentData
    player {
      startTime
      v4ID
      contentType
      title
      permalink
      description
      secondaryTitle
      secondaryGenre
      programmingType
      pid
      image
      airDate
      brandDisplayTitle
      brandMachineName
      colorBrandLogo
      whiteBrandLogo
      resourceId
      regionEntitlementId
      channelId
      sport
      league
      offerType
      tuneIn
      gradientStart
      gradientEnd
      tertiaryTitle
      liveEntitlement
      entitlement
      locked
      endTime
      backgroundImage
      headerTitle
      duration
      rating
      ratingAdvisories
      nielsenSfCode
      ariaLabel
      mpxGuid
      seasonNumber
      episodeNumber
      genre
      amazonGenre
      watchDuration
      watchDurationInMilliseconds
      copyright
      dayPart
      shortDescription
      sunset
      keywords
      seriesShortTitle
      seriesUrlAlias
      lightPrimaryColor
      mpxAccountId
      mpxAdPolicy
      allowMobileWebPlayback
      startRecapTiming
      endRecapTiming
      startTeaserTiming
      endTeaserTiming
      startIntroTiming
      endIntroTiming
      cuePoint
      externalAdvertiserId
      tmsId
      movieShortTitle
      allowSkipButtons
      skipButtonsDuration
      goToButtonText
      goToButtonDestination
      stationId
      callSign
      streamAccessName
    }
    endCard {
      ...lazyEndCard
    }
  }
  analytics {
    offerType
    title
    status
    permalink
    pid
    airDate
    programmingType
    resourceId
    regionEntitlementId
    genre
    secondaryGenre
    listOfGenres
    sport
    league
    isOlympics
    videoBroadcast
    isFullEpisode
    durationInMilliseconds
    programTitle
    nielsenProgen
    nielsenChannel
    nielsenSfCode
    videoTitle
    clipCategory
    webBrandDomain
    episodeNumber
    seasonNumber
    adobeVideoPlatform
    adobeVideoResearchTitle
    adobeContentType
    ottPlatform
    callSign
    dayPart
    liveEntitlement
    entitlement
    locked
    rating
    ratingAdvisories
    brand {
      title
    }
    duration
    tmsId
    isLongFormContent
    mpxGuid
    series
    movie
    titleTmsId
    convivaAssetName
    nielsenBrand
    nielsenClientId
    event
    language
    adobeBrand
  }
}
    `;
export const StreamNotFound = gql `
    fragment streamNotFound on StreamNotFound {
  ...component
  ...section
  data {
    ...componentData
    backgroundImage
  }
}
    `;
export const EventTileData = gql `
    fragment eventTileData on EventTileData {
  ...componentData
  pid
  title
  description
  startTime
  endTime
  backgroundGradientStart
  backgroundGradientEnd
  brandV4ID
  whiteBrandLogo
}
    `;
export const EventTile = gql `
    fragment eventTile on EventTile {
  ...component
  data {
    ...eventTileData
  }
  analytics {
    brand {
      title
    }
    adobeBrand
    adobeContentType
    adobeVideoPlatform
    adobeVideoResearchTitle
    airDate
    analyticsType
    clipCategory
    contentType
    genre
    isOlympics
    league
    listOfGenres
    liveEntitlement
    nielsenBrand
    nielsenProgen
    ottPlatform
    secondaryGenre
    sport
    title
    videoBroadcast
    videoType
  }
}
    `;
export const EventScheduleData = gql `
    fragment eventScheduleData on EventScheduleData {
  ...componentData
  events {
    ...eventTile
  }
}
    `;
export const EventSchedule = gql `
    fragment eventSchedule on EventSchedule {
  ...component
  data {
    ...eventScheduleData
  }
}
    `;
export const GuideProgramData = gql `
    fragment guideProgramData on GuideProgramData {
  ...componentData
  v4ID
  stationId
  callSign
  startSlot
  endSlot
  slotSpan
  image
  switchToNationalStream
  nationalStreamQueryParameters {
    tags
    homeTeam
    awayTeam
  }
  episodeNumber
  mpxGuid
  nextEpisodeMpxGuid
  seasonNumber
  episodeTitle
  programTitle
  programDescription
  ratingWithAdvisories
  startTime
  endTime
  audioDescription
  isNew
  seriesUrlAlias
  backgroundGradientStart
  backgroundGradientEnd
  resourceId
  channelId
  whiteBrandLogo
  machineName
  brandV4ID
  allowLiveToVodButton
  liveToVodButtonDuration
  relativePath
  nextEpisodeRelativePath
  streamAccessName
  tmsId
  notification {
    ...notification
  }
}
    `;
export const GuideProgram = gql `
    fragment guideProgram on GuideProgram {
  ...component
  guideProgramData: data {
    ...guideProgramData
  }
  analytics {
    brand {
      title
    }
    currentVideo {
      tmsId
      mpxGuid
      rating
      relativePath
      programmingType
      seasonNumber
      episodeNumber
      duration
      videoTitle
      series
      movie
      locked
      callSign
      ottPlatform
      airDate
      webBrandDomain
      programTitle
      isFullEpisode
      durationInMilliseconds
      genre
      secondaryGenre
      externalAdvertiserId
      titleTmsId
      dayPart
      videoType
      clipCategory
      adobeVideoPlatform
      adobeContentType
      adobeBrand
      playerUrl
      liveEntitlement
      listOfGenres
      ratingWithAdvisories
      nielsenClientId
      nielsenProgen
      nielsenBrand
      nielsenSfCode
      nielsenChannel
      comscoreCallSign
      league
      isOlympics
      sport
      league
      adobeVideoResearchTitle
      videoBroadCast
      brand {
        title
      }
    }
    nextVideo {
      tmsId
      mpxGuid
      relativePath
      programmingType
      seasonNumber
      episodeNumber
      duration
      videoTitle
      series
      movie
      locked
      brand {
        title
      }
    }
    titlePage {
      series
      movie
      isMovie
      brand {
        title
      }
    }
  }
}
    `;
export const GuideScheduleData = gql `
    fragment guideScheduleData on GuideScheduleData {
  ...componentData
  programs {
    ...guideProgram
  }
}
    `;
export const GuideSchedule = gql `
    fragment guideSchedule on GuideSchedule {
  ...component
  data {
    ...guideScheduleData
  }
}
    `;
export const GuideStreamData = gql `
    fragment guideStreamData on GuideStreamData {
  ...componentData
  lightPrimaryColor
  darkPrimaryColor
  brandDisplayTitle
  machineName
  secondaryTitle
  colorBrandLogo
  whiteBrandLogo
  resourceId
  channelId
  v4ID
  streamAccessName
  stationId
  xyFallback
  callSign
}
    `;
export const GuideStream = gql `
    fragment guideStream on GuideStream {
  ...component
  data {
    ...guideStreamData
  }
  analytics {
    brand {
      title
    }
    callSign
    xyFallback
    nielsenClientId
    nielsenChannel
  }
}
    `;
export const GuideData = gql `
    fragment guideData on GuideData {
  ...componentData
  slotDuration
  slots
  start
  schedules {
    ...guideSchedule
  }
  streams {
    ...guideStream
  }
}
    `;
export const Guide = gql `
    fragment guide on Guide {
  ...component
  data {
    ...guideData
  }
}
    `;
export const BrandLandingPageAnalyticsAttributes = gql `
    fragment brandLandingPageAnalyticsAttributes on BrandLandingPageAnalyticsAttributes {
  brand {
    title
  }
}
    `;
export const BrowsePageAnalyticsAttributes = gql `
    fragment browsePageAnalyticsAttributes on BrowsePageAnalyticsAttributes {
  brand {
    title
  }
}
    `;
export const PageAnalyticsAttributes = gql `
    fragment pageAnalyticsAttributes on PageAnalyticsAttributes {
  series
  brand {
    title
  }
}
    `;
export const StreamPageAnalyticsAttributes = gql `
    fragment streamPageAnalyticsAttributes on StreamPageAnalyticsAttributes {
  pageType
  programmingType
  brand {
    title
  }
  genre
  secondaryGenre
  sport
  league
  rating
  ratingAdvisories
}
    `;
export const SportPageAnalyticsAttributes = gql `
    fragment sportPageAnalyticsAttributes on SportPageAnalyticsAttributes {
  pageType
}
    `;
export const OlympicsHighlightsHubPageAnalyticsAttributes = gql `
    fragment olympicsHighlightsHubPageAnalyticsAttributes on OlympicsHighlightsHubPageAnalyticsAttributes {
  pageType
  pageName
}
    `;
export const OlympicsReplaysHubPageAnalyticsAttributes = gql `
    fragment olympicsReplaysHubPageAnalyticsAttributes on OlympicsReplaysHubPageAnalyticsAttributes {
  pageType
}
    `;
export const TitlePageAnalyticsAttributes = gql `
    fragment titlePageAnalyticsAttributes on TitlePageAnalyticsAttributes {
  series
  movie
  brand {
    title
  }
}
    `;
export const TitleV2PageAnalyticsAttributes = gql `
    fragment titleV2PageAnalyticsAttributes on TitleV2PageAnalyticsAttributes {
  series
  movie
  genre
  secondaryGenres
  isTitleHub
  titleHub
  isSponsoredTitle
  sponsorName
  hasTrailerCTA
  pageType
  brand {
    title
  }
  emptyStateErrorDescription
  isEmpty
  category
  seriesType
  dartTag
  v4ID
  listOfGenres
}
    `;
export const VideoPageAnalyticsAttributes = gql `
    fragment videoPageAnalyticsAttributes on VideoPageAnalyticsAttributes {
  series
  brand {
    title
  }
  title
  movie
  programmingType
  episodeNumber
  seasonNumber
  mpxGuid
  locked
  duration
  playlistTitle
  playlistPosition
  tmsId
  isLongFormContent
  durationInMilliseconds
  airDate
  dayPart
  webBrandDomain
  permalink
  genre
  secondaryGenre
  titleTmsId
  clipCategory
  adobeVideoPlatform
  adobeContentType
  adobeBrand
  convivaAssetName
  videoBroadcast
  isOlympics
  listOfGenres
  rating
  ratingAdvisories
  nielsenProgen
  nielsenBrand
  nielsenSfCode
  nielsenClientId
  nielsenChannel
  ottPlatform
  sport
  event
  league
  language
  entitlement
}
    `;
export const OlympicsVideoPageAnalyticsAttributes = gql `
    fragment olympicsVideoPageAnalyticsAttributes on OlympicsVideoPageAnalyticsAttributes {
  series
  favoritedSeries
  brand {
    title
  }
  title
  movie
  programmingType
  episodeNumber
  seasonNumber
  mpxGuid
  locked
  duration
  playlistTitle
  playlistPosition
  tmsId
  isLongFormContent
  durationInMilliseconds
  airDate
  dayPart
  webBrandDomain
  permalink
  genre
  secondaryGenre
  titleTmsId
  clipCategory
  adobeVideoPlatform
  adobeContentType
  adobeBrand
  convivaAssetName
  videoBroadcast
  isOlympics
  listOfGenres
  rating
  ratingAdvisories
  nielsenProgen
  nielsenBrand
  nielsenSfCode
  nielsenClientId
  nielsenChannel
  ottPlatform
  sport
  event
  league
  language
  entitlement
  games
  gamesList
}
    `;
export const VideoPageData = gql `
    fragment videoPageData on VideoPageData {
  title
  secondaryTitle
  tertiaryTitle
  playlistTitle
  playlistMachineName
  playlistImage {
    ...image
  }
  playlistCreated
  playlistDescription
  description
  shortDescription
  gradientStart
  gradientEnd
  lightPrimaryColor
  brandLightPrimaryColor
  brandDarkPrimaryColor
  seasonNumber
  episodeNumber
  airDate
  rating
  copyright
  locked
  programmingType
  genre
  duration
  permalink
  image
  labelBadge
  mpxGuid
  authEnds
  externalAdvertiserId
  mpxEntitlementWindows {
    availStartDateTime
    availEndDateTime
    entitlement
    device
  }
  tveEntitlementWindows {
    availStartDateTime
    availEndDateTime
    entitlement
    device
  }
  cast {
    characterFirstName
    characterLastName
    talentFirstName
    talentLastName
  }
  v4ID
  seriesShortTitle
  seriesShortDescription
  multiPlatformLargeImage
  multiPlatformSmallImage
  urlAlias
  dartTag
  seriesType
  dayPart
  sunrise
  sunset
  ratingAdvisories
  width
  height
  selectedCountries
  keywords
  watchId
  referenceUrl
  numberOfEpisodes
  numberOfSeasons
  channelId
  resourceId
  mpxAccountId
  mpxAdPolicy
  brandDisplayTitle
  colorBrandLogo
  whiteBrandLogo
  cuePoint
  startRecapTiming
  endRecapTiming
  startTeaserTiming
  endTeaserTiming
  startIntroTiming
  endIntroTiming
  allowSkipButtons
  skipButtonsDuration
  allowMobileWebPlayback
  canonicalUrl
  ariaLabel
  tmsId
  movieShortTitle
}
    `;
export const BrandLandingPageMetadata = gql `
    fragment brandLandingPageMetadata on BrandLandingPageMetadata {
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  lightPrimaryColor
  darkPrimaryColor
  brandLandingHeadline
  brandLandingDescription
  brandLandingLogo
  brandLandingBackgroundImage
  brandLandingBackgroundPreview
}
    `;
export const BrowsePageMetadata = gql `
    fragment browsePageMetadata on BrowsePageMetadata {
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  lightPrimaryColor
  darkPrimaryColor
  browseHeadline
  browseDescription
  browseLogo
  browseBackgroundImage
  browseBackgroundPreview
}
    `;
export const TitleV2HomepagePageData = gql `
    fragment titleV2HomepagePageData on TitleV2HomepagePageData {
  sport
  league
  pageType
  isEmpty
  socialMedia {
    name
    url
    handle
  }
  referenceUrl
  shortDescription
  shortTitle
  isCoppaCompliant
  schemaType
  titleArt {
    ...image
  }
  numberOfEpisodes
  numberOfSeasons
  brandDisplayTitle
  initialSeason
  canonicalUrl
}
    `;
export const MovieHomepagePageData = gql `
    fragment movieHomepagePageData on MovieHomepagePageData {
  gradientStart
  gradientEnd
  lightPrimaryColor
  darkPrimaryColor
  brandLightPrimaryColor
  brandDarkPrimaryColor
  genres
  category
  socialMedia {
    name
    url
    handle
  }
  dartTag
  referenceUrl
  description
  shortDescription
  shortTitle
  isCoppaCompliant
  schemaType
  v4ID
  titleArt {
    ...image
  }
  multiPlatformLargeImage
  multiPlatformSmallImage
  credits {
    personFirstName
    personLastName
    characterFirstName
    characterLastName
  }
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  canonicalUrl
  titleLogo
}
    `;
export const SeriesHomepagePageData = gql `
    fragment seriesHomepagePageData on SeriesHomepagePageData {
  gradientStart
  gradientEnd
  lightPrimaryColor
  darkPrimaryColor
  brandLightPrimaryColor
  brandDarkPrimaryColor
  genres
  category
  seriesType
  socialMedia {
    name
    url
    handle
  }
  dartTag
  referenceUrl
  availableSeasons
  description
  shortDescription
  shortTitle
  isCoppaCompliant
  schemaType
  v4ID
  titleArt {
    ...image
  }
  multiPlatformLargeImage
  multiPlatformSmallImage
  credits {
    personFirstName
    personLastName
    characterFirstName
    characterLastName
  }
  numberOfEpisodes
  numberOfSeasons
  whiteBrandLogo
  colorBrandLogo
  brandDisplayTitle
  canonicalUrl
  titleLogo
}
    `;
export const LdExperiment = gql `
    fragment ldExperiment on LdExperiment {
  name
  bucket
}
    `;
export const BrandItemLabel = gql `
    fragment brandItemLabel on BrandItemLabel {
  ...component
  data {
    ...brandItem
  }
  analytics {
    displayTitle
    brand {
      title
    }
  }
}
    `;
export const LinksSelectableGroup = gql `
    fragment linksSelectableGroup on LinksSelectableGroup {
  ...component
  ...section
  data {
    ...stringSelectableComponentList
  }
  analytics {
    itemLabels
  }
}
    `;
export const LazyLinksSelectableGroup = gql `
    fragment lazyLinksSelectableGroup on LazyLinksSelectableGroup {
  ...component
  ...lazyComponent
}
    `;
export const BrandSelectableComponentList = gql `
    fragment brandSelectableComponentList on BrandSelectableComponentList {
  ...componentData
  initiallySelected
  brandItemLabels {
    ...brandItemLabel
  }
  items {
    ...linksSelectableGroup
    ...lazyLinksSelectableGroup
  }
  optionalTitle: title
}
    `;
export const BrandSelectableGroup = gql `
    fragment brandSelectableGroup on BrandSelectableGroup {
  ...component
  ...section
  data {
    ...brandSelectableComponentList
  }
}
    `;
export const LazyStackGroup = gql `
    fragment lazyStackGroup on LazyStackGroup {
  ...component
  ...section
  ...lazyComponent
}
    `;
export const StackList = gql `
    fragment stackList on StackList {
  ...componentData
  listTitle
  items {
    ...stack
  }
  moreItems {
    ...lazyStackGroup
  }
  viewAllCta {
    ...ctaLink
  }
}
    `;
export const StackGroup = gql `
    fragment stackGroup on StackGroup {
  ...component
  ...section
  data {
    ...stackList
  }
}
    `;
export const GlobalNavItemsNavigationItemData = gql `
    fragment globalNavItemsNavigationItemData on NavigationItemData {
  ...componentData
  title
  machineName
  defaultLogo
  selectedLogo
  focusedLogo
  ariaLabel
  destinationType
  destination
}
    `;
export const GlobalNavItemsNavigationItem = gql `
    fragment globalNavItemsNavigationItem on NavigationItem {
  ...component
  data {
    ...globalNavItemsNavigationItemData
  }
  analytics {
    itemClickedName
  }
}
    `;
export const GlobalNavItemsStringSelectableComponentList = gql `
    fragment globalNavItemsStringSelectableComponentList on StringSelectableComponentList {
  ...componentData
  initiallySelected
  itemLabels
  itemLabelsTitle
  optionalTitle: title
  items {
    ...globalNavItemsNavigationItem
  }
}
    `;
export const GlobalNavItemsLinksSelectableGroup = gql `
    fragment globalNavItemsLinksSelectableGroup on LinksSelectableGroup {
  ...component
  ...section
  data {
    ...globalNavItemsStringSelectableComponentList
  }
  analytics {
    itemLabels
  }
}
    `;
export const PaginatedShelfFeatureTile = gql `
    fragment paginatedShelfFeatureTile on FeatureTile {
  ...component
  data {
    ...featureItem
  }
  analytics {
    series
    brand {
      title
    }
  }
}
    `;
export const PaginatedShelfLazyComponent = gql `
    fragment paginatedShelfLazyComponent on LazyComponent {
  targetComponent
  data {
    ...componentData
    entryField
    queryVariables
    path
  }
}
    `;
export const PaginatedShelfLazyShelf = gql `
    fragment paginatedShelfLazyShelf on LazyShelf {
  ...component
  ...paginatedShelfLazyComponent
  ...section
}
    `;
export const PaginatedShelfTileList = gql `
    fragment paginatedShelfTileList on TileList {
  ...componentData
  machineName
  listTitle
  ariaLabel
  lastModified
  items {
    ...videoTile
    ...videoStoryTile
    ...seriesTile
    ...movieTile
    ...brandTile
    ...paginatedShelfFeatureTile
    ...marketingBand
    ...slideTile
    ...replayTile
    ...upcomingLiveTile
  }
  moreItems {
    ...paginatedShelfLazyShelf
  }
  viewAllCta {
    ...ctaLink
  }
}
    `;
export const Watch = gql `
    fragment watch on Watch {
  watchId
  videoId
  dateTimeWatched
  percentViewed
}
    `;
export const LiveWatch = gql `
    fragment liveWatch on LiveWatch {
  dateTimeWatched
  watchId
  brandId
  brandMachineName
  callSign
  nationalBroadcastType
}
    `;
export const Favorite = gql `
    fragment favorite on Favorite {
  id
  created
  titleId
  urlAlias
  title
}
    `;
export const BonanzaPage = gql `
    query bonanzaPage($id: ID, $name: String!, $queryName: QueryNames, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $nbcAffiliateName: String, $telemundoAffiliateName: String, $language: Languages, $playlistMachineName: String, $mpxGuid: String, $authorized: Boolean, $minimumTiles: Int, $endCardMpxGuid: String, $endCardTagLine: String, $seasonNumber: Int, $creditMachineName: String, $roleMachineName: String, $originatingTitle: String, $isDayZero: Boolean, $pid: Int, $callSign: String) {
  targetedBrands(
    userId: $userId
    platform: $platform
    profile: $profile
    oneApp: $oneApp
    isDayZero: $isDayZero
  )
  ldStateFlag(userId: $userId, ld: $ld)
  bonanzaPage(
    id: $id
    pid: $pid
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    queryName: $queryName
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    nbcAffiliateName: $nbcAffiliateName
    telemundoAffiliateName: $telemundoAffiliateName
    language: $language
    playlistMachineName: $playlistMachineName
    mpxGuid: $mpxGuid
    authorized: $authorized
    minimumTiles: $minimumTiles
    endCardMpxGuid: $endCardMpxGuid
    endCardTagLine: $endCardTagLine
    seasonNumber: $seasonNumber
    creditMachineName: $creditMachineName
    roleMachineName: $roleMachineName
    originatingTitle: $originatingTitle
    isDayZero: $isDayZero
    callSign: $callSign
  ) {
    id
    pageType
    name
    metadata {
      __typename
      ...videoPageData
      ...seriesHomepagePageData
      ...movieHomepagePageData
      ...brandLandingPageMetadata
      ...titleV2HomepagePageData
      ...browsePageMetadata
    }
    analytics {
      ...brandLandingPageAnalyticsAttributes
      ...pageAnalyticsAttributes
      ...olympicsVideoPageAnalyticsAttributes
      ...videoPageAnalyticsAttributes
      ...streamPageAnalyticsAttributes
      ...titlePageAnalyticsAttributes
      ...titleV2PageAnalyticsAttributes
      ...browsePageAnalyticsAttributes
      ...sportPageAnalyticsAttributes
      ...olympicsHighlightsHubPageAnalyticsAttributes
      ...olympicsReplaysHubPageAnalyticsAttributes
    }
    experiments {
      ...ldExperiment
    }
    data {
      featured {
        ...slideshow
        ...hero
        ...ctaHero
      }
      notification {
        ...notification
      }
      sections {
        ...message
        ...smartTile
        ...slideshow
        ...descriptionSection
        ...aboutSection
        ...shelf
        ...stack
        ...onAirNowShelf
        ...lazyOnAirNowShelf
        ...shelfGroup
        ...gridGroup
        ...navigationMenu
        ...tabsSelectableGroup
        ...brandSelectableGroup
        ...linksSelectableGroup
        ...dateTabsSelectableGroup
        ...grid
        ...expiredVideo
        ...coreSDKPlayer
        ...error
        ...guide
        ...eventSchedule
        ...marketingModule
        ...premiumShelf
        ...continuousScroll
        ...groupedContinuousScroll
        ...lazyShelf
        ...placeholderSection
        ...streamNotFound
        ...stackGroup
      }
    }
  }
}
    ${VideoPageData}
${Image}
${SeriesHomepagePageData}
${MovieHomepagePageData}
${BrandLandingPageMetadata}
${TitleV2HomepagePageData}
${BrowsePageMetadata}
${BrandLandingPageAnalyticsAttributes}
${PageAnalyticsAttributes}
${OlympicsVideoPageAnalyticsAttributes}
${VideoPageAnalyticsAttributes}
${StreamPageAnalyticsAttributes}
${TitlePageAnalyticsAttributes}
${TitleV2PageAnalyticsAttributes}
${BrowsePageAnalyticsAttributes}
${SportPageAnalyticsAttributes}
${OlympicsHighlightsHubPageAnalyticsAttributes}
${OlympicsReplaysHubPageAnalyticsAttributes}
${LdExperiment}
${Slideshow}
${Component}
${Section}
${SlideList}
${ComponentData}
${Slide}
${SlideItem}
${Item}
${CtaLink}
${CtaData}
${QrModal}
${QrModalData}
${SmartTile}
${SmartTileData}
${VideoTile}
${VideoItem}
${CtaSlide}
${CtaSlideData}
${CtaSmartTile}
${CtaSmartTileData}
${UpcomingLiveSlide}
${UpcomingLiveSlideData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${Hero}
${HeroData}
${HypermediaLink}
${CtaHero}
${CtaHeroData}
${Overlay}
${OverlayData}
${DescriptionSection}
${DescriptionData}
${Message}
${MessageData}
${AboutSection}
${AboutData}
${Shelf}
${TileList}
${VideoStoryTile}
${VideoStoryItem}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}
${Stack}
${OnAirNowShelf}
${OnAirNowList}
${LazyOnAirNowShelf}
${ShelfGroup}
${ShelfList}
${GridGroup}
${GridList}
${Grid}
${NavigationMenu}
${TabsSelectableGroup}
${StringSelectableComponentList}
${ItemLabelsConfigItem}
${LazyShelfGroup}
${Placeholder}
${NestedLinksSelectableGroup}
${NestedStringSelectableComponentList}
${BrandSelectableGroup}
${BrandSelectableComponentList}
${BrandItemLabel}
${LinksSelectableGroup}
${LazyLinksSelectableGroup}
${DateTabsSelectableGroup}
${DateSelectableComponentList}
${Schedule}
${ScheduleListingList}
${ScheduleListing}
${ScheduleListingData}
${LazySchedule}
${ExpiredVideo}
${CoreSdkPlayer}
${LazyEndCard}
${Error}
${Guide}
${GuideData}
${GuideSchedule}
${GuideScheduleData}
${GuideProgram}
${GuideProgramData}
${GuideStream}
${GuideStreamData}
${EventSchedule}
${EventScheduleData}
${EventTile}
${EventTileData}
${MarketingModule}
${PremiumShelf}
${PremiumTileList}
${PremiumTile}
${PremiumItem}
${ContinuousScroll}
${ContinuousScrollData}
${GroupedContinuousScroll}
${GroupedContinuousScrollData}
${PlaceholderSection}
${StreamNotFound}
${StackGroup}
${StackList}
${LazyStackGroup}`;
export const ScrollFromSeason = gql `
    query scrollFromSeason($urlAlias: String!, $queryName: QueryNames, $seasonNumber: Int!, $name: String!, $type: EntityPageType!, $userId: String!, $platform: SupportedPlatforms!, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $app: NBCUBrands!, $language: Languages, $playlistMachineName: String, $authorized: Boolean, $minimumTiles: Int, $isDayZero: Boolean) {
  scrollFromSeason(
    urlAlias: $urlAlias
    queryName: $queryName
    seasonNumber: $seasonNumber
    name: $name
    type: $type
    userId: $userId
    platform: $platform
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    app: $app
    language: $language
    playlistMachineName: $playlistMachineName
    authorized: $authorized
    minimumTiles: $minimumTiles
    isDayZero: $isDayZero
  ) {
    ...groupedContinuousScroll
  }
}
    ${GroupedContinuousScroll}
${Component}
${Section}
${GroupedContinuousScrollData}
${ComponentData}
${LazyComponent}
${ContinuousScroll}
${ContinuousScrollData}
${VideoTile}
${VideoItem}
${Item}`;
export const BrandTitleCategories = gql `
    query brandTitleCategories($brand: NBCUBrandsPlusAll!, $userId: String!, $platform: SupportedPlatforms!, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean) {
  brandTitleCategories(
    brand: $brand
    userId: $userId
    platform: $platform
    profile: $profile
    ld: $ld
    timeZone: $timeZone
    oneApp: $oneApp
    app: $app
    language: $language
    isDayZero: $isDayZero
  ) {
    ...brandTitleCategoriesLinksSelectableGroup
  }
}
    ${BrandTitleCategoriesLinksSelectableGroup}
${Component}
${Section}
${BrandTitleCategoriesStringSelectableComponentList}
${ComponentData}
${BrandTitleCategoriesGrid}
${BrandTitleCategoriesTileList}
${BrandTitleCategoriesSeriesTile}
${BrandTitleCategoriesSeriesItem}
${BrandTitleCategoriesItem}
${BrandTitleCategoriesMovieTile}
${BrandTitleCategoriesMovieItem}
${LazyGrid}
${LazyComponent}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${LinksSelectableGroupAnalyticsAttributes}`;
export const ComponentsForPlaceholders = gql `
    query componentsForPlaceholders($id: ID, $queryName: QueryNames, $componentConfigs: [String]!, $name: String!, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean, $group: String, $nbcAffiliateName: String, $telemundoAffiliateName: String) {
  componentsForPlaceholders(
    id: $id
    queryName: $queryName
    componentConfigs: $componentConfigs
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    language: $language
    isDayZero: $isDayZero
    group: $group
    nbcAffiliateName: $nbcAffiliateName
    telemundoAffiliateName: $telemundoAffiliateName
  ) {
    placeholderRanges {
      machineName
      range
    }
    components {
      ...shelf
      ...onAirNowShelf
      ...guide
      ...stack
      ...slideshow
      ...stackGroup
      ...groupedContinuousScroll
      ...grid
      ...message
    }
  }
}
    ${Shelf}
${Component}
${Section}
${TileList}
${ComponentData}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}
${OnAirNowShelf}
${OnAirNowList}
${Guide}
${GuideData}
${GuideSchedule}
${GuideScheduleData}
${GuideProgram}
${GuideProgramData}
${GuideStream}
${GuideStreamData}
${Stack}
${Slideshow}
${SlideList}
${Slide}
${CtaSlide}
${CtaSlideData}
${CtaSmartTile}
${CtaSmartTileData}
${UpcomingLiveSlide}
${UpcomingLiveSlideData}
${StackGroup}
${StackList}
${LazyStackGroup}
${GroupedContinuousScroll}
${GroupedContinuousScrollData}
${ContinuousScroll}
${ContinuousScrollData}
${Grid}
${Message}
${MessageData}`;
export const EndCardPage = gql `
    query endCardPage($id: ID, $type: VideoType!, $mpxGuid: String, $pid: Int, $forcedMpxGuid: String, $userId: String!, $timeZone: String!, $platform: SupportedPlatforms!, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $app: NBCUBrands!, $language: Languages, $playlistMachineName: String, $tagLine: String, $isDayZero: Boolean) {
  endCard(
    id: $id
    type: $type
    mpxGuid: $mpxGuid
    pid: $pid
    forcedMpxGuid: $forcedMpxGuid
    userId: $userId
    timeZone: $timeZone
    platform: $platform
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    app: $app
    language: $language
    playlistMachineName: $playlistMachineName
    tagLine: $tagLine
    isDayZero: $isDayZero
  ) {
    ...endCard
    ...endTiles
    __typename
  }
}
    ${EndCard}
${Component}
${EndCardData}
${ComponentData}
${EndCardAlternate}
${EndCardAlternateData}
${VideoTile}
${VideoItem}
${Item}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${UpcomingLiveTile}
${UpcomingLiveItem}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${ReplayTile}
${ReplayTileData}
${EndCardAnalytics}
${EndTiles}
${EndTilesData}`;
export const FeaturedSection = gql `
    query featuredSection($id: ID, $name: String!, $queryName: QueryNames, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $nbcAffiliateName: String, $telemundoAffiliateName: String, $language: Languages, $playlistMachineName: String, $mpxGuid: String, $authorized: Boolean, $minimumTiles: Int, $endCardMpxGuid: String, $endCardTagLine: String, $seasonNumber: Int, $creditMachineName: String, $roleMachineName: String, $originatingTitle: String, $isDayZero: Boolean, $pid: Int) {
  featuredSection(
    id: $id
    pid: $pid
    name: $name
    queryName: $queryName
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    nbcAffiliateName: $nbcAffiliateName
    telemundoAffiliateName: $telemundoAffiliateName
    language: $language
    playlistMachineName: $playlistMachineName
    mpxGuid: $mpxGuid
    authorized: $authorized
    minimumTiles: $minimumTiles
    endCardMpxGuid: $endCardMpxGuid
    endCardTagLine: $endCardTagLine
    seasonNumber: $seasonNumber
    creditMachineName: $creditMachineName
    roleMachineName: $roleMachineName
    originatingTitle: $originatingTitle
    isDayZero: $isDayZero
  ) {
    ...slideshow
    ...hero
    ...ctaHero
    ...featuredSectionShelf
  }
}
    ${Slideshow}
${Component}
${Section}
${SlideList}
${ComponentData}
${Slide}
${SlideItem}
${Item}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SmartTile}
${SmartTileData}
${VideoTile}
${VideoItem}
${CtaSlide}
${CtaSlideData}
${CtaSmartTile}
${CtaSmartTileData}
${UpcomingLiveSlide}
${UpcomingLiveSlideData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${Hero}
${HeroData}
${HypermediaLink}
${CtaHero}
${CtaHeroData}
${Overlay}
${OverlayData}
${DescriptionSection}
${DescriptionData}
${FeaturedSectionShelf}
${TileList}
${VideoStoryTile}
${VideoStoryItem}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}`;
export const GlobalNavigation = gql `
    query globalNavigation($id: ID, $userId: String!, $platform: SupportedPlatforms!, $device: String, $ld: Boolean, $oneApp: Boolean, $profile: JSON, $deepLinkHandle: String, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean) {
  globalNavigation(
    id: $id
    userId: $userId
    platform: $platform
    device: $device
    ld: $ld
    profile: $profile
    oneApp: $oneApp
    deepLinkHandle: $deepLinkHandle
    app: $app
    language: $language
    isDayZero: $isDayZero
  ) {
    ...globalNavItemsLinksSelectableGroup
  }
}
    ${GlobalNavItemsLinksSelectableGroup}
${Component}
${Section}
${GlobalNavItemsStringSelectableComponentList}
${ComponentData}
${GlobalNavItemsNavigationItem}
${GlobalNavItemsNavigationItemData}`;
export const LdStateflag = gql `
    query ldStateflag($userId: String!, $ld: Boolean) {
  ldStateFlag(userId: $userId, ld: $ld)
}
    `;
export const MixedEditorialShelf = gql `
    query mixedEditorialShelf($id: ID, $seoFriendlyName: String!, $name: String!, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $nbcAffiliateName: String, $telemundoAffiliateName: String, $language: Languages, $playlistMachineName: String, $mpxGuid: String, $authorized: Boolean, $minimumTiles: Int, $endCardMpxGuid: String, $endCardTagLine: String, $seasonNumber: Int, $creditMachineName: String, $roleMachineName: String, $isDayZero: Boolean) {
  mixedEditorialShelf(
    id: $id
    seoFriendlyName: $seoFriendlyName
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    nbcAffiliateName: $nbcAffiliateName
    telemundoAffiliateName: $telemundoAffiliateName
    language: $language
    playlistMachineName: $playlistMachineName
    mpxGuid: $mpxGuid
    authorized: $authorized
    minimumTiles: $minimumTiles
    endCardMpxGuid: $endCardMpxGuid
    endCardTagLine: $endCardTagLine
    seasonNumber: $seasonNumber
    creditMachineName: $creditMachineName
    roleMachineName: $roleMachineName
    isDayZero: $isDayZero
  ) {
    ...component
    ...section
    data {
      ...tileList
    }
    analytics {
      itemsList
      listTitle
      isMixedTiles
    }
  }
}
    ${Component}
${Section}
${TileList}
${ComponentData}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}`;
export const OnAirNowShelfPage = gql `
    query onAirNowShelfPage($id: ID, $name: String!, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $nbcAffiliateName: String, $telemundoAffiliateName: String, $language: Languages) {
  onAirNowShelf(
    id: $id
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    nbcAffiliateName: $nbcAffiliateName
    telemundoAffiliateName: $telemundoAffiliateName
    language: $language
  ) {
    ...onAirNowShelf
    __typename
  }
}
    ${OnAirNowShelf}
${Component}
${Section}
${OnAirNowList}
${ComponentData}
${OnAirNowTile}
${OnAirNowItem}
${Notification}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}`;
export const PaginatedComponent = gql `
    query paginatedComponent($id: ID, $cursor: String!, $name: String!, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean) {
  paginatedComponent(
    id: $id
    cursor: $cursor
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    language: $language
    isDayZero: $isDayZero
  ) {
    ...shelf
    ...grid
    ...continuousScroll
    ...stack
  }
}
    ${Shelf}
${Component}
${Section}
${TileList}
${ComponentData}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}
${Grid}
${ContinuousScroll}
${ContinuousScrollData}
${Stack}`;
export const PaginatedShelf = gql `
    query paginatedShelf($id: ID, $cursor: String!, $name: String!, $type: EntityPageType!, $nationalBroadcastType: String, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $ld: Boolean, $oneApp: Boolean, $timeZone: String, $deepLinkHandle: String, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean) {
  paginatedShelf(
    id: $id
    cursor: $cursor
    name: $name
    type: $type
    nationalBroadcastType: $nationalBroadcastType
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    ld: $ld
    oneApp: $oneApp
    timeZone: $timeZone
    deepLinkHandle: $deepLinkHandle
    app: $app
    language: $language
    isDayZero: $isDayZero
  ) {
    ...component
    ...section
    data {
      ...paginatedShelfTileList
    }
    analytics {
      listTitle
      isMixedTiles
    }
  }
}
    ${Component}
${Section}
${PaginatedShelfTileList}
${ComponentData}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PaginatedShelfFeatureTile}
${FeatureItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${ReplayTile}
${ReplayTileData}
${UpcomingLiveTile}
${UpcomingLiveItem}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${PaginatedShelfLazyShelf}
${PaginatedShelfLazyComponent}`;
export const FeaturedShowsSection = gql `
    query featuredShowsSection($userId: String!, $platform: SupportedPlatforms!, $device: String, $oneApp: Boolean, $app: NBCUBrands!, $language: Languages) {
  featuredShowsSection(
    userId: $userId
    platform: $platform
    device: $device
    oneApp: $oneApp
    app: $app
    language: $language
  ) {
    ...shelf
    __typename
  }
}
    ${Shelf}
${Component}
${Section}
${TileList}
${ComponentData}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}`;
export const UserInteractions = gql `
    query userInteractions($userId: String!, $app: NBCUBrands!, $queryName: QueryNames, $platform: SupportedPlatforms!, $ld: Boolean, $isDayZero: Boolean, $profile: JSON, $oneApp: Boolean) {
  userWatches(
    userId: $userId
    app: $app
    queryName: $queryName
    platform: $platform
    ld: $ld
    isDayZero: $isDayZero
    profile: $profile
    oneApp: $oneApp
  ) {
    ...watch
  }
  userLiveWatches(
    userId: $userId
    app: $app
    queryName: $queryName
    platform: $platform
    ld: $ld
    isDayZero: $isDayZero
    profile: $profile
    oneApp: $oneApp
  ) {
    ...liveWatch
  }
  userFavorites(
    userId: $userId
    app: $app
    queryName: $queryName
    platform: $platform
    ld: $ld
    isDayZero: $isDayZero
    profile: $profile
    oneApp: $oneApp
  ) {
    ...favorite
  }
}
    ${Watch}
${LiveWatch}
${Favorite}`;
export const VideosSectionPage = gql `
    query videosSectionPage($id: ID, $type: EntityPageType!, $userId: String!, $platform: SupportedPlatforms!, $device: String, $profile: JSON, $name: String, $seriesName: String, $seasonNumber: Int, $programmingType: String, $currentMpxGuid: String, $oneApp: Boolean, $app: NBCUBrands!, $language: Languages, $isDayZero: Boolean) {
  videosSection(
    id: $id
    type: $type
    userId: $userId
    platform: $platform
    device: $device
    profile: $profile
    name: $name
    seriesName: $seriesName
    seasonNumber: $seasonNumber
    programmingType: $programmingType
    currentMpxGuid: $currentMpxGuid
    oneApp: $oneApp
    app: $app
    language: $language
    isDayZero: $isDayZero
  ) {
    ...shelfGroup
    ...shelf
    __typename
  }
}
    ${ShelfGroup}
${Component}
${Section}
${ShelfList}
${ComponentData}
${Shelf}
${TileList}
${VideoTile}
${VideoItem}
${Item}
${VideoStoryTile}
${VideoStoryItem}
${CtaLink}
${CtaData}
${Image}
${QrModal}
${QrModalData}
${SeriesTile}
${SeriesItem}
${MovieTile}
${MovieItem}
${BrandTile}
${BrandItem}
${PersonTile}
${PersonItem}
${FeatureTile}
${FeatureItem}
${PlaylistTile}
${PlaylistItem}
${MarketingBand}
${MarketingBandData}
${SlideTile}
${SlideItem}
${SmartTile}
${SmartTileData}
${UpcomingLiveSlideTile}
${UpcomingLiveSlideTileData}
${UpcomingModal}
${UpcomingModalData}
${Notification}
${OnAirNowTile}
${OnAirNowItem}
${GenreTile}
${UpcomingLiveTile}
${UpcomingLiveItem}
${ReplayTile}
${ReplayTileData}
${LazyShelf}
${LazyComponent}
${LazyGrid}
${LazyStack}`;
