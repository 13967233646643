import IPlayerControlsStrategy from './IPlayerControlsStrategy';
import { formatTime, getUnixDate } from '../../../../helpers';
import { Language } from '@lightningjs/sdk';
import { getSubtitleForLive } from '../../helpers/metadata';
import { PlayerControlsStates } from '../../../../constants';
import { createProgressHookEvent } from '../hooks/useProgress';
export default class LinearStrategy extends IPlayerControlsStrategy {
    init(stream, program) {
        if (this.parent._getState() !== PlayerControlsStates.PlayOrPause) {
            this.parent._keyMap.reset();
            this.parent._setState(PlayerControlsStates.PlayOrPause);
        }
        const { startTime, endTime } = (program === null || program === void 0 ? void 0 : program.startTime) ? program : stream || {};
        this._startTimeUnix = getUnixDate(startTime);
        this._endTimeUnix = getUnixDate(endTime);
        this._totalTime = this._endTimeUnix - this._startTimeUnix;
        this.parent._title.text.text =
            (program === null || program === void 0 ? void 0 : program.programTitle) || Language.translate('program_unavailable');
        this.parent._secondaryTitle.text.text = getSubtitleForLive(program, stream);
        this.parent._aTime.text.text = formatTime(startTime);
        this.parent._bTime.text.text = formatTime(endTime);
        this._progress.set(createProgressHookEvent({
            currentTime: 0,
            duration: this._totalTime,
        }));
    }
    update() {
        const current = getUnixDate();
        const secondsViewed = current - this._startTimeUnix;
        this._progress.set(createProgressHookEvent(secondsViewed));
    }
}
