import TVPlatform from '../tv-platform';
class SpeechController {
    constructor() {
        this._active = false;
        this._announces = [];
        this._tts = TVPlatform.tts();
    }
    get active() {
        return this._active;
    }
    append(toSpeak) {
        this._announces.push(this._tts.speak(toSpeak));
    }
    cancel() {
        this._announces = [];
        this._tts.cancel();
        this._active = false;
    }
    async speak(series) {
        this._active = true;
        this._announces.push(this._tts.speak(series));
        await this._speakSeries();
    }
    async _speakSeries() {
        while (this._announces.length) {
            const series = this._announces.shift();
            if (!series)
                continue;
            await series.speak();
        }
        this._active = false;
    }
}
export default new SpeechController();
