export default class Announce {
    get phrase() {
        return this._phrase;
    }
    constructor(toSpeak) {
        this.reduceSeries = (acc, curr) => {
            let next;
            if (Array.isArray(curr)) {
                next = curr.reduce(this.reduceSeries, acc);
            }
            else if (typeof curr === 'function') {
                const funcExecuted = curr();
                //@ts-expect-error TS2349: this expression is not callable
                next = (Array.isArray(funcExecuted) ? funcExecuted : [funcExecuted]).reduce(this.reduceSeries, acc);
            }
            else {
                next = acc += (acc.length ? ' ' : '') + curr;
            }
            return next;
        };
        const seriesArray = Array.isArray(toSpeak) ? toSpeak : [toSpeak];
        this._phrase = seriesArray.reduce(this.reduceSeries, '');
    }
}
