import isUndefined from 'lodash/isUndefined';
import { setSmooth } from '../../../helpers';
import { Container } from '../../../api/models';
import { LinksSelectableSpawner } from '../../../api/spawners';
import { brandTilesCategories } from '../../../api/requests';
import { ShowsStates } from '../../../constants';
import ContentPosition from '../../../util/contentPosition';
export const LoadSelectedBrandStateFactory = (base) => class LoadSelectedBrandData extends base {
    $enter() {
        setSmooth(this.widgets.loader, 'visible', 1);
        setSmooth(this.tag('LinksSelectableGroup'), 'alpha', 0);
        setSmooth(this.tag('ErrorMessage'), 'alpha', 0);
        this.loadData().then((success) => {
            var _a, _b;
            if (success) {
                this._setState(ShowsStates.BrandLabelsList);
                setSmooth(this.tag('LinksSelectableGroup'), 'alpha', 1);
                const position = ContentPosition.getPositionForCurrentPage();
                if (!isUndefined(position)) {
                    this._setState(ShowsStates.LinksSelectableGroup);
                    this.linksSelectableGroup.initialFocusState = 'Data';
                    delete this._previousItemSelectedIndex;
                }
                else {
                    (_b = (_a = this.linksSelectableGroup) === null || _a === void 0 ? void 0 : _a.getDataElement()) === null || _b === void 0 ? void 0 : _b.setIndex(0);
                }
            }
            else {
                this._setState(ShowsStates.Error);
            }
        });
    }
    $exit() {
        setSmooth(this.widgets.loader, 'visible', 0);
    }
    _getFocused() {
        return this.brandLabelsList || this;
    }
    set linksSelectableGroup(v) {
        this.linksSelectableGroup.initialFocusState = 'LabelsList';
        v.obj.fontSize = 35;
        if (this._initialCategory) {
            const categoryIndex = v.data.obj.itemLabels.findIndex((category) => this._parseCategory(category) === this._parseCategory(this._initialCategory));
            if (categoryIndex > -1)
                v.data.obj.initiallySelected = categoryIndex;
        }
        this.linksSelectableGroup.spawner = new LinksSelectableSpawner(v);
        this.linksSelectableGroup.data = v;
    }
    async loadData() {
        var _a;
        const brandData = (_a = this._brandSelectableSpawner) === null || _a === void 0 ? void 0 : _a.getBrandData(this._selectedBrandIndex);
        if ((brandData === null || brandData === void 0 ? void 0 : brandData.component) === 'LazyLinksSelectableGroup') {
            //in case of LazyLinksSelectableGroup
            //Check if already data is queried for that network then reuse that data
            if (brandData.queryData) {
                this.linksSelectableGroup = brandData.queryData;
            }
            else {
                const queryData = await brandTilesCategories(brandData.data.queryVariables);
                if (queryData && queryData.data) {
                    brandData.queryData = new Container(queryData.data, 0);
                    this.linksSelectableGroup = brandData.queryData;
                }
                else {
                    return false;
                }
            }
        }
        else {
            //in case of LinkSelectableGroup
            this.linksSelectableGroup = brandData;
        }
        return true;
    }
};
