import { Log, Registry } from '@lightningjs/sdk';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import SpeechController from './SpeechController';
export var AnnouncerEvent;
(function (AnnouncerEvent) {
    AnnouncerEvent["TTS_START"] = "announceStarted";
    AnnouncerEvent["TTS_END"] = "announceEnded";
})(AnnouncerEvent || (AnnouncerEvent = {}));
const ANNOUNCER_TAG = 'Announcer';
class Announcer {
    constructor() {
        this.enabled = false;
        this.debug = false;
        this.announcerTimeout = 300 * 1000;
        this.announcerFocusDebounce = 400;
        this.voiceOutTimeout = 100;
        this.voiceOutDisabled = false;
        this.speechController = SpeechController;
        this.prevFocusPath = [];
        this._events = new Subject();
        this._onError = (error) => {
            this.announceEnded();
            // Won't TVPlatform.reportError since it will send a lot of unneeded events
            if (this.debug)
                Log.error(ANNOUNCER_TAG, error);
        };
        this.announceEnded = () => {
            this.emit(AnnouncerEvent.TTS_END);
        };
        this.debounceAnnounceFocusChange = debounce(this.onFocusChange.bind(this), this.announcerFocusDebounce, { leading: false, trailing: false });
        this.resetFocusTimer = debounce(() => {
            this.prevFocusPath = [];
        }, this.announcerTimeout, { leading: false, trailing: false });
    }
    static getElementName(elm) {
        return elm.ref || elm.constructor.name;
    }
    announce(toAnnounce, { append = false, notification = false } = {}) {
        if (!this.enabled)
            return;
        if (this.voiceOutDisabled && notification)
            this.voiceOutDisabled = false;
        this.debounceAnnounceFocusChange.flush();
        if (append && this.speechController.active) {
            this.speechController.append(toAnnounce);
        }
        else {
            this.cancel();
            this.voiceOut(toAnnounce);
        }
        if (notification) {
            this.voiceOutDisabled = true;
        }
    }
    onFocusChange(focusPath = []) {
        if (!this.enabled)
            return;
        const lastFocusPath = this.prevFocusPath || [];
        const loaded = focusPath.every((elm) => !elm.loading);
        const focusDiff = focusPath.filter((elm) => !lastFocusPath.includes(elm));
        if (!loaded) {
            this.debounceAnnounceFocusChange();
            return;
        }
        this.prevFocusPath = focusPath.slice(0);
        const orderedAnnouncement = focusDiff.reduce((acc, elm) => {
            const elName = Announcer.getElementName(elm);
            if (elm.announce) {
                acc.push([elName, 'Announce', elm.announce]);
            }
            else if (elm.title) {
                acc.push([elName, 'Title', elm.title || '']);
            }
            else {
                acc.push([elName, 'Label', elm.label || '']);
            }
            return acc;
        }, []);
        focusDiff.reverse().reduce((acc, elm) => {
            const elName = Announcer.getElementName(elm);
            if (elm.announceContext) {
                acc.push([elName, 'Context', elm.announceContext]);
            }
            else {
                acc.push([elName, 'No Context', '']);
            }
            return acc;
        }, orderedAnnouncement);
        if (this.debug)
            Log.info(ANNOUNCER_TAG, orderedAnnouncement);
        const toAnnounce = orderedAnnouncement.reduce((acc, a) => {
            //@ts-expect-error TS2345 Arugment of type 'AnnouncmentType' is not assignable to type 'string | string[]'
            if (a[2])
                acc.push(a[2]);
            return acc;
        }, []);
        if (toAnnounce.length) {
            const resultAnnounce = toAnnounce.reduce((acc, val) => {
                if (Array.isArray(val)) {
                    acc = acc.concat(val);
                }
                else {
                    acc.push(val);
                }
                return acc;
            }, []);
            if (this.voiceOutDisabled) {
                if (!this.speechController.active) {
                    this.voiceOutDisabled = false;
                }
                else {
                    this.speechController.append(resultAnnounce);
                    return;
                }
            }
            this.stop();
            this.voiceOut(resultAnnounce);
        }
    }
    get events() {
        return this._events;
    }
    subscribe(handler) {
        if (this.enabled)
            return this._events.subscribe(handler);
    }
    emit(type) {
        this.events.next({
            type,
        });
    }
    stop() {
        this.announceEnded();
        this.resetFocusTimer();
        this.cancel();
    }
    voiceOut(toAnnounce) {
        if (this.voiceOutDisabled)
            return;
        this.announceEnded();
        // timeout is required otherwise cancel isn't working properly
        Registry.setTimeout(() => {
            this.emit(AnnouncerEvent.TTS_START);
            this.speechController
                .speak(toAnnounce)
                .then(this.announceEnded)
                .catch(this._onError)
                .finally(() => {
                this.announceEnded();
                this.voiceOutDisabled = false;
            });
        }, this.voiceOutTimeout);
    }
    cancel() {
        this.speechController.cancel();
    }
}
export default new Announcer();
