export var LANGUAGES;
(function (LANGUAGES) {
    LANGUAGES["SPANISH"] = "es";
    LANGUAGES["DEFAULT"] = "en";
})(LANGUAGES || (LANGUAGES = {}));
export var BRAND;
(function (BRAND) {
    BRAND["bravo"] = "bravo";
    BRAND["cnbc"] = "cnbc";
    BRAND["deportes"] = "deportes";
    BRAND["e"] = "e";
    BRAND["golf"] = "golf";
    BRAND["msnbc"] = "msnbc";
    BRAND["nbc"] = "nbc";
    BRAND["news"] = "news";
    BRAND["nbcsports"] = "nbcsports";
    BRAND["oxygen"] = "oxygen";
    BRAND["syfy"] = "syfy";
    BRAND["telemundo"] = "telemundo";
    BRAND["mun2"] = "mun2";
    BRAND["usa"] = "usa";
})(BRAND || (BRAND = {}));
export var CALL_SIGNS;
(function (CALL_SIGNS) {
    CALL_SIGNS["nbcnews"] = "NBCNN";
    CALL_SIGNS["nbc"] = "KNTV";
    CALL_SIGNS["bravo_east"] = "BRAVOHD";
    CALL_SIGNS["bravo_west"] = "BRAVOHP";
    CALL_SIGNS["cnbc"] = "CHDSTR";
    CALL_SIGNS["e_east"] = "EHD";
    CALL_SIGNS["e_west"] = "EPHD";
    CALL_SIGNS["golf"] = "GOLFHDB";
    CALL_SIGNS["msnbc"] = "MNBCHD";
    CALL_SIGNS["oxygen_east"] = "OXYGNHD";
    CALL_SIGNS["oxygen_west"] = "OXYGNHP";
    CALL_SIGNS["syfy_east"] = "SYFYHD";
    CALL_SIGNS["syfy_west"] = "SYFYHDP";
    CALL_SIGNS["universo_east"] = "UNVSOHD";
    CALL_SIGNS["universo_west"] = "UVSPHST";
    CALL_SIGNS["usa_east"] = "USAHD";
    CALL_SIGNS["usa_west"] = "USAHDP";
    CALL_SIGNS["necn"] = "NECNHD";
    CALL_SIGNS["nbcsbayarea"] = "NBCSBAH";
    CALL_SIGNS["nbcsboston"] = "NBCSBH";
    CALL_SIGNS["nbcscalifornia"] = "NBSCAHD";
    CALL_SIGNS["nbcschicago"] = "NBCSCHD";
    CALL_SIGNS["nbcsphiladelphia"] = "NBCSPAH";
})(CALL_SIGNS || (CALL_SIGNS = {}));
export const WHITE_LABEL_BRANDS = {
    [BRAND.bravo]: {
        name: 'bravo',
        app: 'Bravo App',
        analytics: 'Bravo',
        nielsen: 'Bravo One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'Bravo',
        bundleId: 'com.nbcuni.bravo.xclass',
        liveCallSign: CALL_SIGNS.bravo_east,
    },
    [BRAND.cnbc]: {
        name: 'cnbc',
        app: 'CNBC App',
        analytics: 'CNBC',
        nielsen: 'CNBC',
        language: LANGUAGES.DEFAULT,
        bundleId: 'com.nbcuni.cnbc.xclass',
        liveCallSign: CALL_SIGNS.cnbc,
    },
    [BRAND.deportes]: {
        name: 'deportes',
        app: 'Deportes App',
        analytics: 'Deportes',
        nielsen: 'Deportes',
        language: LANGUAGES.DEFAULT,
        bundleId: 'com.nbcuni.deportes.xclass',
        liveCallSign: CALL_SIGNS.nbcnews,
    },
    [BRAND.e]: {
        name: 'e',
        app: 'E! App',
        analytics: 'E!',
        nielsen: 'E! One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'E!_Entertainment',
        bundleId: 'com.nbcuni.e.xclass',
        liveCallSign: CALL_SIGNS.e_east,
    },
    [BRAND.golf]: {
        name: 'golf',
        app: 'Golf App',
        analytics: 'Golf',
        nielsen: 'Golf',
        language: LANGUAGES.DEFAULT,
        bundleId: 'com.nbcuni.golf.xclass',
        liveCallSign: CALL_SIGNS.golf,
    },
    [BRAND.msnbc]: {
        name: 'msnbc',
        app: 'MSNBC App',
        analytics: 'MSNBC',
        nielsen: 'NBC News',
        language: LANGUAGES.DEFAULT,
        cmpName: 'MSNBC',
        bundleId: 'com.nbcuni.msnbc.xclass',
        liveCallSign: CALL_SIGNS.msnbc,
    },
    [BRAND.nbc]: {
        name: 'nbc',
        app: 'NBC App',
        analytics: 'NBC',
        nielsen: 'NBC Network App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'NBC_Entertainment',
        bundleId: 'com.nbcuni.nbc.xclass',
        liveCallSign: CALL_SIGNS.nbc,
    },
    [BRAND.news]: {
        name: 'news',
        app: 'NBC News App',
        analytics: 'NBC News',
        nielsen: 'NBC News',
        language: LANGUAGES.DEFAULT,
        bundleId: 'com.nbcuni.news.xclass',
        liveCallSign: CALL_SIGNS.nbcnews,
    },
    [BRAND.nbcsports]: {
        configName: 'nbcSports',
        name: 'nbcsports',
        app: 'NBC Sports App',
        analytics: 'NBC Sports',
        nielsen: 'NBC Sports app',
        language: LANGUAGES.DEFAULT,
        cmpName: 'NBCSportsGroupOlympics',
        bundleId: 'com.nbcuni.nbcsports.xclass',
        liveCallSign: CALL_SIGNS.nbc,
    },
    [BRAND.oxygen]: {
        name: 'oxygen',
        app: 'Oxygen App',
        analytics: 'Oxygen',
        nielsen: 'Oxygen One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'Oxygen_Media',
        bundleId: 'com.nbcuni.oxygen.xclass',
        liveCallSign: CALL_SIGNS.oxygen_east,
    },
    [BRAND.syfy]: {
        name: 'syfy',
        app: 'SYFY App',
        analytics: 'Syfy',
        nielsen: 'Syfy One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'SyFy',
        bundleId: 'com.nbcuni.syfy.xclass',
        liveCallSign: CALL_SIGNS.syfy_east,
    },
    [BRAND.telemundo]: {
        name: 'telemundo',
        app: 'Telemundo App',
        analytics: 'Telemundo',
        nielsen: 'Telemundo One App',
        language: LANGUAGES.SPANISH,
        cmpName: 'Telemundo_Networks',
        bundleId: 'com.nbcuni.telemundo.xclass',
        liveCallSign: CALL_SIGNS.nbcnews,
    },
    [BRAND.mun2]: {
        name: 'mun2',
        app: 'Universo App',
        analytics: 'Universo',
        nielsen: 'Universo One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'Universo',
        bundleId: 'com.nbcuni.mun2.xclass',
        liveCallSign: CALL_SIGNS.universo_east,
    },
    [BRAND.usa]: {
        name: 'usa',
        app: 'USA App',
        analytics: 'USA',
        nielsen: 'USA One App',
        language: LANGUAGES.DEFAULT,
        cmpName: 'USA_Network',
        bundleId: 'com.nbcuni.usanetwork.xclass',
        liveCallSign: CALL_SIGNS.usa_east,
    },
};
export const CLIENT_NAME = 'oneapp';
export const WEB_PLATFORM = 'web';
export var PROGRAMMING_TYPES;
(function (PROGRAMMING_TYPES) {
    PROGRAMMING_TYPES["NONE"] = "None";
    PROGRAMMING_TYPES["FULL_EPISODE"] = "Full Episode";
    PROGRAMMING_TYPES["MOVIE"] = "Movie";
    PROGRAMMING_TYPES["CLIP"] = "Clip";
    PROGRAMMING_TYPES["HIGHLIGHT"] = "Highlight";
    PROGRAMMING_TYPES["HIGHLIGHTS"] = "Highlights";
    PROGRAMMING_TYPES["SFVOD"] = "Short-Form VOD";
    PROGRAMMING_TYPES["SLE"] = "Single Live Event";
    PROGRAMMING_TYPES["FER"] = "Full Event Replay";
    PROGRAMMING_TYPES["EXCERPT"] = "Excerpt";
    PROGRAMMING_TYPES["WEB_EXCLUSIVE"] = "Web Exclusive";
    PROGRAMMING_TYPES["SNEAK_PEAK"] = "Sneak Peek";
    PROGRAMMING_TYPES["TEASER_TRAILER"] = "Teaser Trailer";
    PROGRAMMING_TYPES["OLYMPICS"] = "Olympics";
    PROGRAMMING_TYPES["TRAILER"] = "Trailer";
    PROGRAMMING_TYPES["PREVIEW"] = "Preview";
    PROGRAMMING_TYPES["CURRENT_PREVIEW"] = "Current Preview";
    PROGRAMMING_TYPES["RECAP"] = "Recap";
})(PROGRAMMING_TYPES || (PROGRAMMING_TYPES = {}));
export const VOD_PROGRAMMING_TYPES = new Set([
    PROGRAMMING_TYPES.FULL_EPISODE,
    PROGRAMMING_TYPES.MOVIE,
    PROGRAMMING_TYPES.CLIP,
    PROGRAMMING_TYPES.HIGHLIGHT,
    PROGRAMMING_TYPES.HIGHLIGHTS,
    PROGRAMMING_TYPES.SFVOD,
    PROGRAMMING_TYPES.EXCERPT,
    PROGRAMMING_TYPES.WEB_EXCLUSIVE,
    PROGRAMMING_TYPES.SNEAK_PEAK,
    PROGRAMMING_TYPES.TEASER_TRAILER,
]);
export const CLIP_PROGRAMMING_TYPES = new Set([
    PROGRAMMING_TYPES.CLIP,
    PROGRAMMING_TYPES.EXCERPT,
    PROGRAMMING_TYPES.HIGHLIGHT,
    PROGRAMMING_TYPES.HIGHLIGHTS,
    PROGRAMMING_TYPES.TEASER_TRAILER,
    PROGRAMMING_TYPES.SNEAK_PEAK,
    PROGRAMMING_TYPES.TRAILER,
    PROGRAMMING_TYPES.WEB_EXCLUSIVE,
    PROGRAMMING_TYPES.PREVIEW,
    PROGRAMMING_TYPES.CURRENT_PREVIEW,
    PROGRAMMING_TYPES.RECAP,
]);
export const XY_FALLBACK_TYPES = {
    x: 'X',
    y: 'Y',
    none: 'None',
};
export var STORAGE_KEYS;
(function (STORAGE_KEYS) {
    STORAGE_KEYS["MPARTICLE"] = "mpid";
    STORAGE_KEYS["PREVIOUS_STREAM"] = "PREVIOUS_STREAM";
    STORAGE_KEYS["PREVIOUS_CHANNEL"] = "PREVIOUS_CHANNEL";
    STORAGE_KEYS["PREVIOUS_CALLSIGN"] = "PREVIOUS_CALLSIGN";
    STORAGE_KEYS["REFERRING_SHELF"] = "REFERRING_SHELF";
    STORAGE_KEYS["PLAYLIST_MACHINE_NAME"] = "PLAYLIST_MACHINE_NAME";
    STORAGE_KEYS["USER_OPT_OUT"] = "USER_OPT_OUT";
    STORAGE_KEYS["APP_LOADED_TIME"] = "APP_LOADED_TIME";
    STORAGE_KEYS["SMART_TILE"] = "SMART_TILE";
    STORAGE_KEYS["APP_LAUNCH_TYPE"] = "APP_LAUNCH_TYPE";
    STORAGE_KEYS["APP_LANGUAGE"] = "appLanguage";
    STORAGE_KEYS["IS_NOT_DAY_ZERO"] = "isNotDayZero";
    STORAGE_KEYS["PREFERRED_AUDIO_LANG"] = "preferredAudioLang";
})(STORAGE_KEYS || (STORAGE_KEYS = {}));
export var LegacyStoreKey;
(function (LegacyStoreKey) {
    LegacyStoreKey["deviceId"] = "app.deviceID";
    LegacyStoreKey["ccOptions"] = "bhm.cc.options";
    LegacyStoreKey["accessToken"] = "nbcProfile.accessToken";
    LegacyStoreKey["language"] = "i18n.lan";
})(LegacyStoreKey || (LegacyStoreKey = {}));
export var ENTITY_TYPES;
(function (ENTITY_TYPES) {
    ENTITY_TYPES["VIDEO"] = "video";
    ENTITY_TYPES["REPLAY"] = "replay";
    ENTITY_TYPES["VIDEOS"] = "videos";
    ENTITY_TYPES["SERIES"] = "series";
    ENTITY_TYPES["MOVIE"] = "movie";
    ENTITY_TYPES["ON_AIR"] = "onAir";
    ENTITY_TYPES["SLIDE"] = "slide";
    ENTITY_TYPES["UPCOMING_LIVE_TILE"] = "upcomingLiveTile";
    ENTITY_TYPES["UPCOMING_LIVE_SLIDE"] = "upcomingLiveSlide";
    ENTITY_TYPES["UPCOMING_LIVE_SLIDE_TILE"] = "upcomingLiveSlideTile";
    ENTITY_TYPES["BRAND"] = "brand";
    ENTITY_TYPES["SHOWS"] = "shows";
    ENTITY_TYPES["SMART_TILES"] = "smartTiles";
    ENTITY_TYPES["CTA_SMART_TILES"] = "ctaSmartTiles";
    ENTITY_TYPES["SEARCH_RESULTS"] = "searchResults";
    ENTITY_TYPES["RECOMMENDED"] = "recommended";
    ENTITY_TYPES["NETWORK"] = "network";
    ENTITY_TYPES["STREAM"] = "stream";
    ENTITY_TYPES["PLAYLIST"] = "playlist";
    ENTITY_TYPES["LIVE_TO_VOD"] = "liveToVod";
    ENTITY_TYPES["MARKETING_MODULE"] = "marketingModule";
})(ENTITY_TYPES || (ENTITY_TYPES = {}));
export var REGISTRATION_REFERRERS;
(function (REGISTRATION_REFERRERS) {
    REGISTRATION_REFERRERS["VOD"] = "VOD Asset";
    REGISTRATION_REFERRERS["LIVE"] = "Live Stream";
    REGISTRATION_REFERRERS["MVPD"] = "MVPD Login";
    REGISTRATION_REFERRERS["TEMP_PASS"] = "Temp Pass";
})(REGISTRATION_REFERRERS || (REGISTRATION_REFERRERS = {}));
export var CONTENT_TYPE;
(function (CONTENT_TYPE) {
    CONTENT_TYPE["LINEAR"] = "LINEAR";
    CONTENT_TYPE["PLAYMAKER"] = "PLAYMAKER";
    CONTENT_TYPE["SLE"] = "SLE";
    CONTENT_TYPE["FER"] = "FER";
    CONTENT_TYPE["VOD"] = "VOD";
})(CONTENT_TYPE || (CONTENT_TYPE = {}));
export var MENU_ITEM_TYPE;
(function (MENU_ITEM_TYPE) {
    MENU_ITEM_TYPE["DIVIDER"] = "divider";
    MENU_ITEM_TYPE["ITEM"] = "item";
})(MENU_ITEM_TYPE || (MENU_ITEM_TYPE = {}));
export var USER_OPT_OUT_PREFERENCE;
(function (USER_OPT_OUT_PREFERENCE) {
    USER_OPT_OUT_PREFERENCE[USER_OPT_OUT_PREFERENCE["ALLOW_SALE"] = 0] = "ALLOW_SALE";
    USER_OPT_OUT_PREFERENCE[USER_OPT_OUT_PREFERENCE["DISALLOW_SALE"] = 1] = "DISALLOW_SALE";
})(USER_OPT_OUT_PREFERENCE || (USER_OPT_OUT_PREFERENCE = {}));
export var ENTITLEMENT;
(function (ENTITLEMENT) {
    ENTITLEMENT["ENTITLED"] = "Entitled";
    ENTITLEMENT["FREE"] = "Free";
})(ENTITLEMENT || (ENTITLEMENT = {}));
export var SLE_STATUS;
(function (SLE_STATUS) {
    SLE_STATUS["EXPIRED"] = "expired";
    SLE_STATUS["LIVE"] = "live";
    SLE_STATUS["UPCOMING"] = "upcoming";
})(SLE_STATUS || (SLE_STATUS = {}));
export var LINEAR_CHANNEL_TYPE;
(function (LINEAR_CHANNEL_TYPE) {
    LINEAR_CHANNEL_TYPE["SPECIALITY"] = "speciality-channel";
})(LINEAR_CHANNEL_TYPE || (LINEAR_CHANNEL_TYPE = {}));
export var COMPONENT_TYPES;
(function (COMPONENT_TYPES) {
    COMPONENT_TYPES["SLIDE_SHOW"] = "Slideshow";
    COMPONENT_TYPES["SHELF"] = "Shelf";
    COMPONENT_TYPES["ON_AIR_NOW_SHELF"] = "OnAirNowShelf";
    COMPONENT_TYPES["LAZY_SHELF"] = "LazyShelf";
    COMPONENT_TYPES["BRAND_SELECTABLE_GROUP"] = "BrandSelectableGroup";
    COMPONENT_TYPES["LINK_SELECTABLE_GROUP"] = "LinksSelectableGroup";
    COMPONENT_TYPES["LAZY_LINK_SELECTABLE_GROUP"] = "LazyLinksSelectableGroup";
    COMPONENT_TYPES["LAZY_ON_AIR_NOW_SHELF"] = "LazyOnAirNowShelf";
    COMPONENT_TYPES["GRID"] = "Grid";
    COMPONENT_TYPES["SMART_TILE"] = "SmartTile";
    COMPONENT_TYPES["END_TILES"] = "EndTiles";
    COMPONENT_TYPES["GUIDE"] = "Guide";
    COMPONENT_TYPES["GUIDE_SCHEDULE"] = "GuideSchedule";
    COMPONENT_TYPES["EVENT_SCHEDULE"] = "EventSchedule";
    COMPONENT_TYPES["SLIDE"] = "Slide";
    COMPONENT_TYPES["UPCOMING_LIVE_SLIDE"] = "UpcomingLiveSlide";
    COMPONENT_TYPES["UPCOMING_LIVE_TILE"] = "UpcomingLiveTile";
    COMPONENT_TYPES["SPORTS_LIVE_GUIDE_TILE"] = "SportsLiveGuideTile";
    COMPONENT_TYPES["MESSAGE"] = "Message";
    COMPONENT_TYPES["REPLAY_TILE"] = "ReplayTile";
    COMPONENT_TYPES["GROUPED_CONTINUOUS_SCROLL"] = "GroupedContinuousScroll";
    COMPONENT_TYPES["CONTINUOUS_SCROLL"] = "ContinuousScroll";
    COMPONENT_TYPES["PLACEHOLDER"] = "PlaceholderSection";
    COMPONENT_TYPES["PLACEHOLDER_ELEMENT"] = "Placeholder";
    COMPONENT_TYPES["MARKETING_MODULE"] = "MarketingModule";
    COMPONENT_TYPES["VIDEO_TILE"] = "VideoTile";
    COMPONENT_TYPES["END_CARD_ALTERNATE"] = "EndCardAlternate";
    COMPONENT_TYPES["END_CARD_ALTERNATE_SLE"] = "EndCardAlternateSLE";
    COMPONENT_TYPES["NESTED_LINK_SELECTABLE_GROUP"] = "NestedLinksSelectableGroup";
    COMPONENT_TYPES["STACK"] = "Stack";
    COMPONENT_TYPES["STACK_GROUP"] = "StackGroup";
})(COMPONENT_TYPES || (COMPONENT_TYPES = {}));
export const END_CARD_TYPES = {
    [PROGRAMMING_TYPES.FULL_EPISODE]: 'episode',
    [PROGRAMMING_TYPES.MOVIE]: 'movieFullVideo',
    [PROGRAMMING_TYPES.SFVOD]: 'sportsVideo',
    [PROGRAMMING_TYPES.SLE]: 'singleLiveEvents',
    DEFAULT: 'segment',
};
export const MAX_TIME_OUT = 2880000; // 2 DAYS
export const MAX_HOURS_PASSED = 24;
export const PLACEHOLDER_TIMEOUT = 4000; // 4 SECONDS
export const SINGLE_LIVE_EVENT = 'Single Live Event';
export const svgRegExp = /\.(svg)/;
export const httpsRegExp = /^(?:https?:)?\/\//i;
export const FALLBACK_TIMEZONE = 'America/New_York'; // EST -05:00
export const SHOWS_SHELF_TITLE = 'Shows';
export const SHIMMER_LOADER_TILES = 10;
export const AD_TYPES = {
    PREROLL: 'Preroll',
    MIDROLL: 'Midroll',
};
export var DESTINATION_TYPES;
(function (DESTINATION_TYPES) {
    DESTINATION_TYPES["video"] = "video";
    DESTINATION_TYPES["series"] = "series";
    DESTINATION_TYPES["internalPage"] = "internalPage";
    DESTINATION_TYPES["playlist"] = "playlist";
    DESTINATION_TYPES["singleEvent"] = "singleEvent";
})(DESTINATION_TYPES || (DESTINATION_TYPES = {}));
export var AUTH_REDIRECT;
(function (AUTH_REDIRECT) {
    AUTH_REDIRECT["redirect"] = "redirect";
})(AUTH_REDIRECT || (AUTH_REDIRECT = {}));
export const END_OF_ROW = 'End of row';
export var TRICK_PLAY_ACTIONS;
(function (TRICK_PLAY_ACTIONS) {
    TRICK_PLAY_ACTIONS["pause"] = "Content Paused";
    TRICK_PLAY_ACTIONS["play"] = "Playing Content";
    TRICK_PLAY_ACTIONS["pauseSeekable"] = "Content paused at";
    TRICK_PLAY_ACTIONS["seconds_out"] = "seconds out of";
    TRICK_PLAY_ACTIONS["playSeekable"] = "Playing at";
    TRICK_PLAY_ACTIONS["fast_forward"] = "Fast forwarding at";
    TRICK_PLAY_ACTIONS["rewind"] = "Rewiding at";
})(TRICK_PLAY_ACTIONS || (TRICK_PLAY_ACTIONS = {}));
export var TRICK_PLAY_ELEMENTS;
(function (TRICK_PLAY_ELEMENTS) {
    TRICK_PLAY_ELEMENTS["playButton"] = "Play";
    TRICK_PLAY_ELEMENTS["pauseButton"] = "Pause";
})(TRICK_PLAY_ELEMENTS || (TRICK_PLAY_ELEMENTS = {}));
export var TIME_TTS;
(function (TIME_TTS) {
    TIME_TTS["hours"] = "hours";
    TIME_TTS["minutes"] = "minutes";
    TIME_TTS["seconds"] = "second";
})(TIME_TTS || (TIME_TTS = {}));
export var EPG_VIEWS;
(function (EPG_VIEWS) {
    EPG_VIEWS["channels"] = "channels";
    EPG_VIEWS["filters"] = "filters";
})(EPG_VIEWS || (EPG_VIEWS = {}));
export const STREAM_ON_PEACOCK = 'STREAM ON PEACOCK';
export const ADOBE_PATH_GEO_ERROR = 'You do not have full subscriptions to view the requested content. Please upgrade your programming then log back in to view content.';
export const AUDIO_DESCRIPTION_TAG = 'ad';
export const LIVE_PLAYER_TAG = 'Live Player';
export const UNAUNTHENTICATED = 'Unauthenticated';
