import { Language } from '@lightningjs/sdk';
import ModalManager, { CloseReason } from '../../../../lib/ModalManager';
export const NOTIFICATION_TIMEOUT = 3000;
export class ActivationStrategy {
    constructor(ctx) {
        this._ctx = ctx;
    }
    drawText() {
        const title = Language.translate('profile_link_msg');
        this._ctx.tag('Title').text.text = title;
        return [title];
    }
    destroy() {
        clearTimeout(this._timeout);
    }
    success(activationType) {
        this._activationType = activationType;
        // Wait for events to dispatch at notifyUser function
        this._timeout = setTimeout(() => this._endNotification(), NOTIFICATION_TIMEOUT);
    }
    _endNotification() {
        clearTimeout(this._timeout);
        this._redirect();
    }
    _redirect() {
        ModalManager.close(CloseReason.COMPLETED);
    }
}
