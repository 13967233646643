import BasePlayer from '../BasePlayer';
import { createProgressHookEvent, useProgress, } from '../../../components/player/PlayerControls/hooks/useProgress';
import { inRange } from 'lodash';
class HasSeeking extends BasePlayer {
}
var SeekDirection;
(function (SeekDirection) {
    SeekDirection["NONE"] = "";
    SeekDirection["FF"] = "ff";
    SeekDirection["REW"] = "rew";
})(SeekDirection || (SeekDirection = {}));
export const WithSeeking = (component) => class extends component {
    constructor() {
        super(...arguments);
        this.TIME_STEPS = 10;
        this._seekDirection = SeekDirection.NONE;
        this._updateTimeOffset = (offset) => {
            var _a, _b;
            if ((_a = this._player) === null || _a === void 0 ? void 0 : _a.isPlaying())
                (_b = this._player) === null || _b === void 0 ? void 0 : _b.pause();
            const [current, duration, seekableRange] = this._progress.value;
            const time = current + this.TIME_STEPS * offset;
            if (!inRange(time, (seekableRange === null || seekableRange === void 0 ? void 0 : seekableRange[0]) || 0, (seekableRange === null || seekableRange === void 0 ? void 0 : seekableRange[1]) || duration))
                return;
            this._progress.set(createProgressHookEvent(time));
        };
    }
    _init() {
        super._init();
        this._progress = useProgress();
    }
    $forward() {
        var _a, _b;
        this._controls.seekIcon = 1;
        this._updateTimeOffset(1);
        if (this._seekDirection !== SeekDirection.FF) {
            this._seekDirection = SeekDirection.FF;
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireClick('Forward');
            (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireSeekStart();
        }
    }
    $rewind() {
        var _a, _b;
        this._controls.seekIcon = -1;
        this._updateTimeOffset(-1);
        if (this._seekDirection !== SeekDirection.REW) {
            this._seekDirection = SeekDirection.REW;
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireClick('Rewind');
            (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireSeekStart();
        }
    }
    $seek() {
        var _a;
        const currentTime = this._progress.value[0];
        // seek adjust is used for SLEs currently.
        // add time required to get the player in line with the seek bar (seconds).
        const seekAdjust = this._progress.value[3] || 0;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.seek(currentTime + seekAdjust);
    }
    /**
     * Rewinds content 5 seconds before the end.
     * Used in non-production environments.
     */
    $seekToTheEnd() {
        var _a;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.pause();
        this._controls.seekIcon = 1;
        const duration = this._progress.value[1];
        this._progress.set(createProgressHookEvent(duration - 5));
        this.$seek();
    }
    _onSeekEnd() {
        var _a;
        super._onSeekEnd();
        this._seekDirection = SeekDirection.NONE;
        (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireSeekEnd();
    }
};
